import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // Sélection

  selectAllRows(e) {
    let selectInput = $(e.target)
    let checked = selectInput.is(":checked")
    $(this.element).find(".list-group-item .row-selector").filter(function() {
      return !this.disabled;
    }).prop("checked", checked).each(function() {
      let event = new Event('change');
      this.dispatchEvent(event);
    })
  }

  selectRow(e) {
    e.preventDefault();
    e.stopPropagation();
    let listItem = $(e.currentTarget)
    let itemSelectors = listItem.find(".row-selector")
    let checked = !itemSelectors.first().is(":checked")
    itemSelectors.prop("checked", checked)

    let event = new Event('change');
    itemSelectors[0].dispatchEvent(event);

    this._updateSelectAllInputs()
  }

  _updateSelectAllInputs() {
    let selectAllinputs = $(this.element).find(".all-rows-selector")
    let rowSelectors = $(this.element).find(".row-selector:not(.all-rows-selector)")
    if (rowSelectors.length == rowSelectors.filter(":checked").length) {
      selectAllinputs.prop("checked", true).prop("indeterminate", false)
    } else if (rowSelectors.filter(":checked").length == 0 ){
      selectAllinputs.prop("checked", false).prop("indeterminate", false)
    } else {
      selectAllinputs.prop("checked", false).prop("indeterminate", true);
    }
  }
}

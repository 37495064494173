import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": this.data.get("message")}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }
}

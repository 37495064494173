import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['country', 'selectLocalityWrapper', 'manualZipWrapper', 'manualLocalityWrapper'];

  connect() {
    this.showHideManualLocality()
  }

  showHideManualLocality(){
    let c = this.countryTarget.dataset.addressCountriesWithZips

    if(c.includes($(this.countryTarget).val())){
      $(this.manualZipWrapperTarget).addClass("d-none")
      $(this.manualLocalityWrapperTarget).addClass("d-none")
      $(this.selectLocalityWrapperTarget).removeClass("d-none")
    }else{
      $(this.selectLocalityWrapperTarget).addClass("d-none")
      $(this.manualZipWrapperTarget).removeClass("d-none")
      $(this.manualLocalityWrapperTarget).removeClass("d-none")
    }
  }

}

// Nécessaire, aussi utilisé dans controllers/child_loader_controller.js
import { InitBehaviours }  from "../initializer.js"

document.addEventListener("turbo:load", function() {
  InitBehaviours()
  // Ajoute la casse custom-control-label pour faire fonctionner les custom inputs
  // ex: sexe Femelle/Mâle sur http://localhost:5004/horses/new
})

// Nécessaire, ex: quand submit un form avec erreur, sinon le InitBehaviours() ne se lance pas
document.addEventListener("turbo:render", function() {
  InitBehaviours()
  // Ajoute la casse custom-control-label pour faire fonctionner les custom inputs
  // ex: sexe Femelle/Mâle sur http://localhost:5004/horses/new
})
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const ctrl = this
    $(this.element).find(".nav-tabs .nav-link, .nav-item .nav-link").on("click", function(e) {
      e.preventDefault()
      ctrl._showTab(this)
    })
    $(this.element).find(".tab-link").on("click", function(e) {
      e.preventDefault()
      let linkedTab = $($(this).attr("href"));
      if (linkedTab.length > 0) {
        ctrl._showTab(linkedTab)
      }
    })

    if (this.data.get("lastopen")) {
      /*this.navtabsKey = "navTabs_"+this.data.get("key")

      if (localStorage.getItem(this.navtabsKey)) {
        const activeTab = $(this.element).find("[data-navtabs-value="+localStorage.getItem(this.navtabsKey)+"]").first()
        if (activeTab) {
          ctrl._showTab(activeTab)
        }
      }*/

      this._setEditHorseHREF($('.nav-link.active').data("navtabsValue"));
    } else {

    }
  }

  _setEditHorseHREF(tabValue) {
    if ($('#edit-horse-link').length && ["gen", "sign", "ppl", "coupl", "lab"].includes(tabValue)) {
      const url = new URL($('#edit-horse-link').get(0).href);
      url.searchParams.delete('tab');
      url.searchParams.append('tab', tabValue);
      $('#edit-horse-link').get(0).href = url;
    }
  }

  _showTab(tabEl) {
    let tabValue = $(tabEl).data("navtabsValue");

    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set("tab", tabValue);

    if (this.data.get("lastopen")) {
      //localStorage.setItem(this.navtabsKey, $(this).data("navtabs-value"))

      history.replaceState(null, null, "?" + queryParams.toString());

      this._setEditHorseHREF(tabValue);
    }

    if (this.data.get('reloadPage') && this.data.get('reloadPage') == "true") {
      window.location.href = "?" + queryParams.toString()
    } else {
      $(tabEl).tab('show')
    }
  }

}

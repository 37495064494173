import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import AwsS3 from "@uppy/aws-s3"
import Dashboard from "@uppy/dashboard"
import French from '@uppy/locales/lib/fr_FR';
import English from '@uppy/locales/lib/en_US';
import Dutch from '@uppy/locales/lib/nl_NL';

export default class extends Controller {

  connect() {
    this.uploaded = false

    const Locale = this._getLocale()
    const maxSize = 5368709120
    
    this.uppy = new Uppy({
      locale: Locale,
      restrictions: {
        maxFileSize: maxSize
      },
      autoProceed: true,
      onBeforeFileAdded: (currentFile, files) => {
        const extensions = ['.pdf', '.png', '.jpg', '.jpeg', '.bmp', '.tiff', '.gif', '.svg', '.webp', '.mp4', '.mpeg', '.mov', '.avi', '.wmv', '.ogg', '.webm', '.3gp', '.3g2', '.xls', '.xlsx', '.docx', '.doc', '.ppt', '.pptx', '.odt', '.ods', '.msg', '.eml'   ];
        const extension = currentFile.name.split('.').pop();
        if (!extensions.includes('.' + extension)) {
          return false; // Rejeter le fichier si l'extension n'est pas dans la liste
        }
        return true; // Accepter le fichier pour l'upload
      }
    })

    this.uppy.use(Dashboard, {
        trigger: "#create_studbook_document_button",
        note: I18n.t("studbook_documents.note", { max_size: this._bytesToSize(maxSize) }),
      })
      .use(AwsS3, {
      getUploadParameters(file) {
        const endpoint = document.querySelector("[data-uppy-studbook-documents-endpoint]").getAttribute("data-uppy-studbook-documents-endpoint");

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
          },
          body: JSON.stringify({ filename: file.name, contentType: file.type }),
        }).then((response) => {
          return response.json()
        }).then((data) => {
          return {
            method: data.method,
            url: data.url,
            fields: data.fields,
            headers: {
              'Content-Type': file.type,
            },
          }
        })
      }
    })

    this.uppy.on('complete', (result) => {
      this.uploaded = true

      this._showSnackbar(I18n.t("studbook_documents.flash.create.document.success"))
    })

    this.uppy.on('dashboard:modal-closed', () => {
      if (this.uploaded) {
        this.reloadWindow()
      }
    });

    let ctrl = this

    $("#deleteStudbookDocumentModal").on("ajax:success", function(e){
      ctrl.reloadWindow()

      ctrl._showSnackbar(I18n.t("studbook_documents.flash.destroy.document.success"))
    });
  }

  disconnect() {
    this.uppy.close()
  }

  reloadWindow() {
    window.location.reload()
  }

  showDeleteStudbookDocumentModal(e) {
    e.preventDefault()

    if (e.currentTarget.dataset.link != "") {
      document.getElementById("deleteStudbookDocumentModal").querySelector(".delete-studbook-document-link").href = e.currentTarget.dataset.link
      $("#deleteStudbookDocumentModal").modal("show")
    }
  }

  _getLocale(){
    switch(I18n.locale){
      case "fr": return French
      case "en": return English
      case "nl": return Dutch
      default: return French
    }
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

  _bytesToSize(bytes) {
     let sizes = ['octets', 'ko', 'Mo', 'Go', 'To'];
     if (bytes == 0) return '0 Byte';
     let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
     return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }
}

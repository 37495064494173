import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this._toggleBackBtn()
    let ctrl = this
    window.onpopstate = function(event) {
      if (event.state && event.state.type == "childContent") {
        ctrl._goToOrLoad(event.state.childContent)
      } else {
        // Trigger de la fermeture du child container => updatde de la calsse du body
        $("body").removeClass("has-child-content")

        ctrl.close()
      };

    };


  }

  close() {
    // TODO remplace remove() by nice transition...
    this.element.remove()

  }


  back(e) {
    e.preventDefault();
    window.history.back();
    this._toggleBackBtn()
  }

  OLDback(e) {
    e.preventDefault();
    let thisElement = $(this.element)
    if (thisElement.find(".child-content").length > 1) {
      thisElement.find(".child-content").last().remove();
      thisElement.find(".child-content").last().removeClass("d-none").addClass("animated fadeInLeft");
      this._toggleBackBtn()
    } else {
      thisElement.find(".btn-back").addClass("d-none")
    }
  }


  _goToOrLoad(childContent) {

    let thisElement = $(this.element)
    if (thisElement.find(".child-content[data-child-content-id='"+childContent.id+"']").length > 0) {
      this._goTo(childContent.id)
    } else {
      this._load(childContent)
    }
  }

  _goTo(contentId) {
    let thisElement = $(this.element)
    if (thisElement.find(".child-content[data-child-content-id='"+contentId+"']").length > 0) {
      while(!(thisElement.find(".child-content").last().is(".child-content[data-child-content-id='"+contentId+"']"))) {
        thisElement.find(".child-content").last().remove();
      }
      thisElement.find(".child-content").last().removeClass("d-none").addClass("animated fadeInLeft");
    }
    this._toggleBackBtn()
  }

  _load(childContent) {
    let eventType = "childContent.show"
    let eventDetails = {
      "title": "Info Cheval",
      "placeholder": "horse-show",
      "content": childContent.path,
      "type": "horse"
    }
    let loadEvent = new CustomEvent(eventType, {"detail": eventDetails});
    document.dispatchEvent(loadEvent)
  }


  _toggleBackBtn() {
    let thisElement = $(this.element)
    if (thisElement.find(".child-content").length > 1) {
      thisElement.find(".btn-back").removeClass("d-none")
    } else {
      thisElement.find(".btn-back").addClass("d-none")
    }

  }



}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['legalFormIdInput', 'legalFormOtherInput', 'legalFormOtherInputWrapper'];

  connect() {
    this.showHideLegalFormOther()
  }

  showHideLegalFormOther(){
    if ($(this.legalFormIdInputTarget).val() === "0") {
      $(this.legalFormOtherInputWrapperTarget).removeClass('d-none')
    } else {
      $(this.legalFormOtherInputWrapperTarget).addClass('d-none')
      $(this.legalFormOtherInputTarget).val('')
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var _this = this
    this.snackbarTemplate = this.element.content
    // Add an event listener
    document.addEventListener("snackbar.show", function(e) {
      _this.showSnackbar(e.detail); // Prints "Example of an event"
    });
  }

  triggerSnackbar(data) {
    data= {"message": "Test"}
    var event = new CustomEvent("snackbar.show", data);
    this.element.dispatchEvent(event);
  }

  showSnackbar(data) {
    var newSnackbar = document.importNode(this.snackbarTemplate, true);

    //["webkitTransitionEnd", "transitionEnd"].forEach(function(en) {
    //  console.log("add event listenet" + en)
    //  newSnackbar.firstElementChild.addEventListener(en, function(e){
    //    console.log("animation end")
    //    setTimeout(function() {
    //      newSnackbar.firstElementChild.classList.toggle("show");
    //    }, 6e3);
    //  })
    //});
    var snackbar = newSnackbar.firstElementChild
    //var snackbarBody = snackbar.getElementsByClassName("snackbar-body")[0]
    //snackbarBody.textContent = data.message
    snackbar.getElementsByClassName("snackbar-body")[0].textContent = data.message;
    if (data.action) {
      // TODO : créer action
    } else {
      snackbar.removeChild(snackbar.getElementsByClassName("snackbar-btn")[0]);
    }
    document.querySelector("body").appendChild(newSnackbar);

    setTimeout(function() {
      snackbar.classList.add("show")
    }, 200);

    setTimeout(function() {
      snackbar.classList.remove("show");
    }, 6e3);
    //console.log(data.body)
  }

  railsUjsComplete(event) {
    let [xhr, status] = event.detail;
      let response
    try {
         response = JSON.parse(xhr.response);
    } catch (e) {

         response = JSON.parse("{}");
        console.log(response)
    } finally {
      if (response.notice && response.notice.length > 0) {
        this.showSnackbar({message: response.notice})
      }
    }


  }



  toggle() {
    //ToggleClass(this.element, "show")
    if (this.element.classList) {
      this.element.classList.toggle("show");
    } else {
        // For IE9
        var classes = this.element.className.split(" ");
        var i = classes.indexOf("show");

        if (i >= 0)
            classes.splice(i, 1);
        else
            classes.push("show");
        this.element.className = classes.join(" ");
    }
  }


}

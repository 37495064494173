import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['content'];

  connect() {
    if(this.hasContentTarget){
      this._fitToParent(this.contentTarget);
    }
  }

  expand(e) {
    $(".expandable.expanded").removeClass("expanded")
    $(this.element).addClass("expanded")
    e.preventDefault()
    e.stopPropagation()
    $(this.contentTarget).focus()
    $(this.element).find(".expandable-toggle").each(function(index){
      let icon = $(this).find(".material-icons")
      if (icon.text()=="keyboard_arrow_down") {
        icon.css("transform","rotate(180deg)")
      }
    })
  }

  toggle(e) {
    if ($(this.element).hasClass("expanded")) {
      this.unexpand(e)
    } else {
      this.expand(e)
    }
  }

  unexpand(e){
    $(this.element).removeClass("expanded")
    $(this.element).find(".expandable-toggle").each(function(index){
      let icon = $(this).find(".material-icons")
      if (icon.text()=="keyboard_arrow_down") {
        icon.css("transform","rotate(0deg)")
      }
    })
    e.preventDefault()
    e.stopPropagation()
  }




  /**
   * @private
   */
  _scaleAmountNeededToFit(el, margin = 0) {
    const parentSize = {
      width: el.parentElement.clientWidth - margin * 2,
      height: el.parentElement.clientHeight - margin * 2
    };
    return Math.min(parentSize.width / el.clientWidth,
      parentSize.height / el.clientHeight);
  }

  /**
   * @private
   */
  _fitToParent(el, margin=0) {
    const scale = this._scaleAmountNeededToFit(el, margin);
    el.style.transformOrigin = "0 0";
    el.style.transform = `translate(${margin}px, ${margin}px) scale(${scale})`;
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = []
  
  connect() {
    //$("body").css("position", "relative")

    if ($("nav li a[href^='#'].active").get(0)) {
      $('html, body').animate({
        scrollTop: $($("nav li a[href^='#'].active").get(0).hash).offset().top
      }, 300);
    }

    $("nav li a[href^='#']").on('click', function(event) {
      let target = this.hash

      event.preventDefault()

      return $('html, body').animate({
        scrollTop: $(this.hash).offset().top
      }, 300);
    });

    $("body").scrollspy({ target: "#" + this.element.id })
  }

}

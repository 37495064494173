import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // Supprime lelement si la fonction print n'est pas disponible
    if (typeof window.print != 'function') {
	     $(this.element).remove()
    }
  }

  printPage(e) {
    e.preventDefault();
    window.print();
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.originContentPadding = $(".main-container").css("padding-top")
    this._delay(100).then(() => this.show())
  }

  disconnect() {
      this._unpadContent()
  }

  show() {
    $(this.element).addClass("show")
    this._padContent()
  }

  hide(){
    $(this.element).removeClass("show")
    this._unpadContent()
    this._delay(200).then(() => $(this.element).remove())
  }


  _padContent() {
    $(".main-container").css("padding-top", this._getBannerHeight()+$("#header-navbar").height())
  }

  _unpadContent() {
    $(".main-container").css("padding-top", this.originContentPadding)
  }

  _getBannerHeight() {
    let bannerHeight = $(this.element).height();
    return bannerHeight;
  }

  _delay(ms) {
    return new Promise(
      (resolve) => setTimeout(resolve, ms)
    );
  }

}

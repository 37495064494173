import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    let ctrl = this
    $(document).on("ajax:error", function(event){
      let [data, status, xhr] = event.detail;
      ctrl._processError(event, xhr)
    });
    
    $(document).ajaxError(function(event, xhr, settings){
      ctrl._processError(event, xhr)
    });
  }

  _processError(event, xhr){
    if(xhr.status != 422 || !xhr.getResponseHeader("content-type")){
      return
    }

    event.preventDefault()

    if(xhr.getResponseHeader("content-type").indexOf('application/json') > -1 || xhr.getResponseHeader("content-type").indexOf('text/plain') > -1 || xhr.getResponseHeader("content-type").indexOf('text/javascript') > -1){
      let r = $.parseJSON(xhr.responseText)

      if(r.error && r.error == "InvalidAuthenticityToken"){

        $('[data-controller*="select2"]').select2('close');
        
        $('.modal').modal('hide');

        $("#invalidAuthenticityTokenModal").modal("show");
      }
    }
  }

  reloadPage(e) {
    e.preventDefault()
    window.location.reload();
  }

}

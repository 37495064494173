import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "select", "nested"]

  refresh() {
    const refreshUrl = this.data.get("url")
    $(this.contentTarget).load(refreshUrl)
  }

  changeUrl(e) {
    const element = e.currentTarget
    console.log(element)
    const url = element.dataset.refreshableUrl
    console.log(url)
    this.data.set("url", url)
  }

  selectAndLoad(e) {
    const element = e.currentTarget
    if ($(element).hasClass("selected")) {
      console.log("element is already selected")
    } else {
      console.log("select element :")
      console.log(element)
      // Marquer l'element comme selectionné
      $(element).addClass("selected")
      console.log($(e.selectTargets))
      $(this.selectTargets).not(element).removeClass("selected")
      // Changer l'URL
      const url = element.dataset.refreshableUrl
      console.log(url)
      $(this.nestedTargets).filter("form").attr("action", url)
      $(this.nestedTargets).filter("a").attr("href", url)
      this.data.set("url", url)
      // Rafraichir le contenu
      this.refresh()
    }

  }

}

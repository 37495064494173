import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [];

  connect() {
    $(window).on('popstate', function(event) {
      var state = event.originalEvent.state
      if(state !== null && $('[name="q"]')){
        $('[name="q"]').val(state.q)
        window.location.reload()
      }
    });
  }

  setHistory(event) {
    let url = window.location.href.split('?')[0] + "?q=" + $('[name="q"]').val()
    window.history.pushState({q: $('[name="q"]').val()}, "", encodeURI(url))
  }

}

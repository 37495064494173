import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['selectable']

  initialize() {
    this.onClickEvent = this.onClickEvent.bind(this)
  }

  connect() {
    this.paramsToAdd = new URLSearchParams()

    this._fillParameters(this.selectableTargets)

    this.element.querySelectorAll(this.data.get("target-classes")).forEach(item => {
      item.addEventListener('click', this.onClickEvent)
    })

    console.log(decodeURIComponent(this.paramsToAdd.toString()))
  }

  onClickEvent(e) {
    e.preventDefault()

    let params = new URLSearchParams(e.target.search)

    for (let [key, val] of this.paramsToAdd.entries()) {
      params.append(key, val);
    }

    console.log(decodeURIComponent(this.paramsToAdd.toString()))

    window.location.href = `${location.pathname}?${params}`
  }

  addParam(e) {
    e.preventDefault();
    e.stopPropagation();

    let paramName = e.currentTarget.dataset.bulkSelectionParamName
    let paramValue = e.currentTarget.dataset.bulkSelectionParamValue

    let currentParameters = this.paramsToAdd.getAll(paramName)

    currentParameters = currentParameters.filter(function(value, index, arr){
      return value != paramValue;
    });

    this.paramsToAdd.delete(paramName)

    let ctrl = this

    currentParameters.forEach(function(param){
      ctrl.paramsToAdd.append(paramName, param)
    })

    if (e.currentTarget.checked) {
      this.paramsToAdd.append(paramName, paramValue)
    }

    console.log(decodeURIComponent(this.paramsToAdd.toString()))
  }

  _fillParameters(parameters) {
    let ctrl = this

    parameters.forEach(function(parameter){
      if (parameter.dataset.bulkSelectionParamType == 'checkbox') {
        if (parameter.checked) {
          ctrl.paramsToAdd.append(parameter.dataset.bulkSelectionParamName, parameter.dataset.bulkSelectionParamValue)
        }
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ['container'];

  /**
   * Initialize bindings on connection to the DOM
   */
  connect() {

    for (const el of this.containerTarget.querySelectorAll('[data-binding-type]')) {
      if (el.dataset.bindingType != "actAsRadio") {
        this._runBindings(el);
      }
    }
  }

  /**
   * Updates bindings for the current element.
   * @param {Event} e - an event with a currentTarget DOMElement
   */
  update(e) {

    this._runBindings(e.currentTarget);
  }

  /**
   * Binds the input element to one or more DOM elements
   * @param {DOMElement} element - The input to run bindings on
   */
  bind(element) {
    let value = element.value;

    let to = element.dataset.to;

    for (const el of this._bindingElements(element.dataset.to)) {
      this._setValue(el, value);
    }
    return true;
  }

  /**
   * Shows one or more DOMElements if the input is checked. Hides otherwise.
   * @param {DOMElement} element - The input to run bindings on
   */
  showIfChecked(element) {
    let checked = element.checked;

    for (const el of this._bindingElements(element.dataset.show)) {
      this._toggleElement(el, checked);
    }
    return true;
  }


  disableIfValueIs(element) {
    if (element.tagName == "FIELDSET") {
      var subElement = element.querySelector(':checked')
      if(subElement) {
        element.value = subElement.value
      } else {
        element.value = 0
      }
    }
    if (element.value == element.dataset.when) {
      for (const el of this._bindingElements(element.dataset.show)) {
        $(el).attr("disabled", "disabled").addClass("disabled").parents(".form-group").addClass("disabled").find("label").addClass("disabled")
      }
    } else {
      for (const el of this._bindingElements(element.dataset.show)) {
        $(el).removeAttr("disabled").removeClass("disabled").parents(".form-group").removeClass("disabled").find("label").removeClass("disabled")
      }
    }
  }

  checkIfValueIs(element) {
    if (element.tagName == "FIELDSET") {
      var subElement = element.querySelector(':checked')
      if(subElement) {
        element.value = subElement.value
      } else {
        element.value = 0
      }
    }
    if (element.value == element.dataset.when) {
      for (const el of this._bindingElements(element.dataset.check)) {
        $(el).prop("checked", true).parents("div, fieldset").first().addClass("animated heartBeat")
      }
    }
  }


  /**
   * Shows one or more DOMElements if the input is checked. Hides otherwise.
   * @param {DOMElement} element - The input to run bindings on
   */
  showIfValueIs(element) {

    // Set value of fieldset / div to their inner component.value (rdio or checkbox)
    if (element.tagName == "FIELDSET") {
      var checkedElement = element.querySelector(':checked')
      if(checkedElement) {
        element.value = checkedElement.value
      } else {
        element.value = 0
      }

    }
    let checked = (element.value == element.dataset.when);


    for (const el of this._bindingElements(element.dataset.show)) {
      this._toggleElement(el, checked);
    }
    return true;
  }

  /**
   * Hides one or more DOMElements if the input value is empty. Shows otherwise.
   * @param {DOMElement} element - The input to run bindings on
   */
  hideIfBlank(element) {
    let blank = element.value == '';

    for (const el of this._bindingElements(element.dataset.hide)) {
      this._toggleElement(el, !blank);
    }
  }

  actAsRadio(element) {
    if ($(element).is(":checked")) {
      let radioGroup = element.dataset.group
      $("[data-group='"+radioGroup+"']").not(element).not(":checkbox, :radio").val(null).change()
      $("[data-group='"+radioGroup+"']").not(element).filter(":checkbox, :radio").prop("checked", false)
    }
  }

  /**
   * @private
   * @param {DOMElement} el
   */
  _runBindings(el) {
    for (const type of el.dataset.bindingType.split(' ')) {
      this[type](el);
    }
  }

  /**
   * @private
   * @param {DOMElement} el - the element to change
   * @param {String} value - the string to set the element content to
   */
  _setValue(el, value) {
    el.textContent = value;
    $(el).val(value)
  }

  /**
   * @private
   * @param {DOMElement} el
   * @param {Boolean} visible
   */
  _toggleElement(el, visible) {
    if (el.classList) {
      el.classList.add('animated');
      if (visible) {
        el.classList.remove('invisible');
        el.classList.add('fadeIn');
        el.classList.remove('fadeOut');
      } else {
        el.classList.remove('fadeIn');
        el.classList.add('fadeOut');
      }
    } else {
      if (visible) {
        el.removeAttribute('hidden');
      } else {
        el.setAttribute('hidden', 'hidden');
      }
    }

    return true;
  }

  /**
   * @private
   * @param {String} name - the name of the binding reference
   */
  _bindingElements(name) {
    return document.querySelectorAll(`[data-binding-ref="${name}"]`);
  }
}

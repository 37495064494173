import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formElement"]

  connect() {
  }

  change(e) {
    this.formElementTarget.checked = !this.formElementTarget.checked

    $(this.element).toggleClass("selected", this.formElementTarget.checked)

    let event = new Event('change', { bubbles: true })
    this.formElementTarget.dispatchEvent(event)

    if(!this.formElementTarget.checked && $(this.element).parents(".chips-container").find(".chip-checkbox.selected").length == 0){
      let toCheck = $(this.element).parents(".chips-container").find(".chip-checkbox:not(.selected)").not(this.element).first()

      if(toCheck.length == 1){
        toCheck.click()

        // let event = new Event('change', { bubbles: true })
        // toCheck.dispatchEvent(event)
        
        e.preventDefault()
      }
    }
  }
}

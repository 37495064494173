import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['items', 'counter'];

  connect() {
  }

  countItems() {
    let itemCount = $(this.itemsTarget).find(".list-group-item, .countable_item").length
    this._updateCounter(itemCount)
  }

  _updateCounter(itemCount) {
    $(this.counterTarget).text(itemCount+" "+I18n.t(this.data.get("entryName"), {"count": itemCount}))
  }

}

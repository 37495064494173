import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['results'];


  connect() {
    this._linkToRemoteForms()
  }

  updateResults(resultsHtml) {
    $(this.resultsTarget).replaceWith($(resultsHtml).addClass("animated fadeIn"));
  }

  _linkToRemoteForms() {
    const ctlr = this
    $(this.data.get("remote-forms")).on("ajax:success", function(event){
      let [data, status, xhr] = event.detail;
      let resultsHtml = xhr.response
      ctlr.updateResults(resultsHtml);
    })
  }



}

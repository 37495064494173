import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['addTo', 'source', 'fields', 'hideAfter'];

  connect() {
    
  }

  addDataGroup(e) {
    let dataGroup = $("<div></div>");
    dataGroup.addClass("data-group animated fadeIn");
    let inputs = $(this.sourceTarget).find("input.form-control, input.custom-control-input, select.form-control");
    if(inputs.length > 1) {
      inputs.each(function(i, e){

      })
    } else {
      let [data, status, xhr] = e.detail;
      let response = JSON.parse(xhr.response);

      let dataSpan = $("<div></div>");
      dataSpan.addClass("data-value")

      if(response.addable_html){
        dataSpan.html(response.addable_html)
      }else{
        dataSpan.text(inputs.first().val())
      }
      
      dataGroup.append(dataSpan)
      $(this.sourceTarget).before(dataGroup)
    }

    inputs.val("")
  }

  addRaw(e) {
    let inputs = $(this.sourceTarget).find("input.form-control, input.custom-control-input, select.form-control");

    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    $(this.addToTarget).append(response.addable_html)

    $(inputs).not(".boolean").val("").prop("checked", false)

    $(inputs).prop("checked", false)
  }

  hideFields() {
    if (this.hasFieldsTarget) {
      $(this.fieldsTargets).removeClass("animated fadeIn").addClass("d-none")
    }

    if (this.hasHideAfterTarget) {
      $(this.hideAfterTargets).removeClass("animated fadeIn").removeClass("d-none")
    }
  }

  showFields(e) {
    e.preventDefault()

    if (this.hasFieldsTarget) {
      $(this.fieldsTargets).addClass("animated fadeIn").removeClass("d-none")
    }

    if (this.hasHideAfterTarget) {
      $(this.hideAfterTargets).removeClass("animated fadeIn").addClass("d-none")
    }
  }

  removeHideAfterElements(e) {
    if (this.hasHideAfterTarget) {
      $(this.hideAfterTargets).remove()
    }
  }

  reloadPage() {
    window.location.reload();
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const modalOptions = [];
    const modalElement = $(this.element)
    modalOptions.autoShow = this.data.get("init")

    if (document.querySelector('.modal-backdrop.fade')) {
      document.querySelector('.modal-backdrop.fade').remove()
    }

    if (modalOptions.autoShow && modalOptions.autoShow != null && modalOptions.autoShow != "false" ) {
      modalElement.modal()
      modalElement.modal('show')
    }
  }

  submitForm(e){
    e.preventDefault()

    let formId = e.currentTarget.dataset.submitFormId

    let action = e.currentTarget.dataset.submitFormAction

    if($("#" + formId).length){

      if(action != ""){
        $("#" + formId).prepend('<input name="' + action + '" type="hidden" />')
      }

      document.getElementById(formId).dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
    }
  }

  close(e) {
    $(this.element).modal('hide')
  }

}

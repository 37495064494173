// JS pour le comportement des navbar

//Main navigation scroll spy for shadow
// from: http://stacktips.com/tutorials/bootstrap/creating-bootstrap4-fixed-navbar-with-shadow-on-scroll
var headerContent = document.getElementById("header-content")
var pageFab = $("#page-fab")
$(window).scroll(function() {
  var y = $(window).scrollTop();
  if (y > 0) {
    $('body').addClass('--not-top');
    $("#header-navbar").addClass('--not-top');
  } else {
    $("#header-navbar").removeClass('--not-top');
    $('body').removeClass('--not-top');
  }

  if (headerContent && pageFab) {
    var headerContentPositionBottom = headerContent.getBoundingClientRect().bottom

    if (headerContentPositionBottom < 77) {
      pageFab.addClass('--not-top');
    } else {
      pageFab.removeClass('--not-top');
    }
  }

});

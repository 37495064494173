import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'itemCount', 
    'itemHorsesCount', 
    'itemContactsCount', 
    'linkAddCartHorsesToExportList', 
    'linkAddCartContactsToExportList', 
    'linkEmptyCartHorses', 
    'linkEmptyCartContacts',
    'htmlEmptyCartHorses',
    'htmlEmptyCartContacts'
  ];

  connect() {
    
  }

  itemAdded(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);
    if(response.id){
      $(e.currentTarget).addClass('disabled').prop('disabled', true)

      $(this.itemCountTargets).html(response.items_count)

      if (response.items_horses_count != undefined) {
        $(this.itemHorsesCountTargets).html(response.items_horses_count)

        if (response.items_horses_count > 0) {
          $(this.linkAddCartHorsesToExportListTargets).removeClass('disabled')
          $(this.linkEmptyCartHorsesTargets).removeClass('disabled')

          $(this.htmlEmptyCartHorsesTargets).addClass('d-none')
        }
      }
      
      if (response.items_contacts_count != undefined) {
        $(this.itemContactsCountTargets).html(response.items_contacts_count)

        if (response.items_contacts_count > 0) {
          $(this.linkAddCartContactsToExportListTargets).removeClass('disabled')
          $(this.linkEmptyCartContactsTargets).removeClass('disabled')

          $(this.htmlEmptyCartContactsTargets).addClass('d-none')
        }
      }

      let removeButtons = $(e.currentTarget).parents(".list-group-item").find('[data-cart-action-type="remove"]')

      removeButtons.removeClass('disabled').prop('disabled', false)

      this._showSnackbar(I18n.t("cart_items.flash.add_" + response.item_type.toLowerCase() + "_to_cart.success"))
    }

    $(".tooltip.show").remove()
  }

  showExportModal(e) {
    e.preventDefault()

    let modalType = $(e.currentTarget).data('cartModalType')
    
    if (modalType == 'horses') {
      $('#addCartToExportListModal .modal-title').text(I18n.t("modal.add_cart_to_export_list.title_horses"))

      $('input[name="add_cart_to_export_list[export_list_type]"]').val('horses')

      $('input#existing_export_list').attr('data-toggle-ref', 'existing_export_list_horses')

      $('[data-toggle-ref="existing_export_list_horses"]').prop('checked', 'checked').trigger('change')

      $('[data-toggle-ref="existing_export_list_contacts"]').parents('.custom-control.custom-radio').first().addClass('d-none')
      $('[data-toggle-ref="existing_export_list_contacts"]').find('input').prop('disabled', true)
      $('[data-toggle-ref="existing_export_list_horses"]').parents('.custom-control.custom-radio').first().removeClass('d-none')
      $('[data-toggle-ref="existing_export_list_horses"]').find('input').removeProp('disabled')

      $("#addCartToExportListModal").modal("show");
    } else if (modalType == 'contacts') {
      $('#addCartToExportListModal .modal-title').text(I18n.t("modal.add_cart_to_export_list.title_contacts"))

      $('input[name="add_cart_to_export_list[export_list_type]"]').val('contacts')

      $('input#existing_export_list').attr('data-toggle-ref', 'existing_export_list_contacts')

      $('[data-toggle-ref="existing_export_list_contacts"]').prop('checked', 'checked').trigger('change')

      $('[data-toggle-ref="existing_export_list_horses"]').parents('.custom-control.custom-radio').first().addClass('d-none')
      $('[data-toggle-ref="existing_export_list_horses"]').find('input').prop('disabled', true)
      $('[data-toggle-ref="existing_export_list_contacts"]').parents('.custom-control.custom-radio').first().removeClass('d-none')
      $('[data-toggle-ref="existing_export_list_contacts"]').find('input').removeProp('disabled')

      $("#addCartToExportListModal").modal("show");
    }
  }

  itemNotAdded(e){
    $(".tooltip.show").remove()

    this._showSnackbar(I18n.t("cart_items.flash.add_to_cart.fail"))
  }

  itemRemoved(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    if(response.id){
      $(e.currentTarget).addClass('disabled').prop('disabled', true)

      let addButtons = $(e.currentTarget).parents(".list-group-item").find('[data-cart-action-type="add"]')

      if (addButtons.length) {
        addButtons.removeClass('disabled').prop('disabled', false)
      } else {
        $(e.currentTarget).parents(".list-group-item").first().fadeTo(1000, 0.01, function(){ 
          $(this).slideUp(150, function() {
            $(this).remove();
          });
        });
      }

      $(this.itemCountTargets).html(response.items_count)
      
      if (response.items_horses_count != undefined) {
        $(this.itemHorsesCountTargets).html(response.items_horses_count)

        if (response.items_horses_count <= 0) {
          $(this.linkAddCartHorsesToExportListTargets).addClass('disabled')
          $(this.linkEmptyCartHorsesTargets).addClass('disabled')

          $(this.htmlEmptyCartHorsesTargets).removeClass('d-none')
        }
      }
      
      if (response.items_contacts_count != undefined) {
        $(this.itemContactsCountTargets).html(response.items_contacts_count)

        if (response.items_contacts_count <= 0) {
          $(this.linkAddCartContactsToExportListTargets).addClass('disabled')
          $(this.linkEmptyCartContactsTargets).addClass('disabled')

          $(this.htmlEmptyCartContactsTargets).removeClass('d-none')
        }
      }

      this._showSnackbar(I18n.t("cart_items.flash.remove_" + response.item_type.toLowerCase() + "_from_cart.success"))
    }

    $(".tooltip.show").remove()
  }

  itemNotRemoved(e){
    $(".tooltip.show").remove()

    this._showSnackbar(I18n.t("cart_items.flash.remove_from_cart.fail"))
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

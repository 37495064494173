import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['field'];

  getValueFromUrl(e) {
    let field = this.fieldTarget
    let paramName = this.data.get("param")
    let params = {}
    params[paramName] = $(field).val()
    $.ajax({
      dataType: "json",
      url: this.data.get("url"),
      data: params
    }).done(function(result){
      if (result && result.value) {
        $(field).val(result.value)
        field.dispatchEvent(new CustomEvent('change', { detail: { from_magic_wand: true } }));
      }
    });
  }


}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  showUploadStudbookDocumentModal(e) {
    e.preventDefault()

    let studbookDocumentTypeId = document.querySelector("select[name='filters[studbook_document_type_id]']").value
    
    if (studbookDocumentTypeId != "") {
      document.getElementById("uploadStudbookDocumentModal").querySelector("input[name='studbook_document[displayed_studbook_document_type_id]']").value = studbookDocumentTypeId
      document.getElementById("studbook_document_studbook_document_type_id").value = studbookDocumentTypeId.toString()
    }

    $("#uploadStudbookDocumentModal").modal("show")
  }

  showDeleteStudbookDocumentModal(e) {
    e.preventDefault()

    if (e.currentTarget.dataset.link != "") {
      let studbookDocumentTypeId = document.querySelector("select[name='filters[studbook_document_type_id]']").value
      document.getElementById("uploadStudbookDocumentModal").querySelector("input[name='studbook_document[displayed_studbook_document_type_id]']").value = studbookDocumentTypeId

      document.getElementById("deleteStudbookDocumentModal").querySelector(".delete-studbook-document-link").href = e.currentTarget.dataset.link

      if (studbookDocumentTypeId != "") {
        let url = new URL(e.currentTarget.dataset.link)
        url.searchParams.set("displayed_studbook_document_type_id", studbookDocumentTypeId)
        document.getElementById("deleteStudbookDocumentModal").querySelector(".delete-studbook-document-link").href = url.href
      }
      
      $("#deleteStudbookDocumentModal").modal("show")
    }
  }
}

import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ['marecard', 'stallioncard', 'carryingmarecard', 'stallionId', 'globalCoupler'];


  connect() {
    let crtl = this
    $(this.element).on("change", "input, texteare, select", function(e){crtl._onInputChange(e)})

    // Trigger du change pous les elements qui ont une valeur
    $(this.element).find("input, texteare, select").each(function(e){
      if ($(this).val().length > 1) {
        $(this).trigger("change")
      }
    })
    $(this.element).on("repeatable.add", ".repeatable", function(e){crtl._onRowRepeat(e)})
    $(this.element).on("repeatable.remove", ".repeatable", function(e){crtl._onRowRemove(e)})

    if(this.hasStallionIdTarget && $(this.stallionIdTarget).val() != ''){
      this._updateSelectedStallion($('*[data-stallion-id="' + $(this.stallionIdTarget).val() + '"]').first())
    }

    // si une date de saillie est présente, empêcher la modification de l'année de monte
    if($('.coupling_card_couplings_date .coupling-date').filter(function() { return this.value; }).length ) {
      $('[name="coupling_card[year]"]:radio').attr("disabled", "disabled")
    }

    if($('[name="coupling_card[embryo_transfer]"]:checked').val()) {
      $('[name="coupling_card[coupling_mode]"][value="5"]').attr("disabled", "disabled")
    }

    if($('[name="coupling_card[coupling_mode]"]:checked').val() == 5) {
      $('[name="coupling_card[embryo_transfer]"]').attr("checked", false).attr("disabled", "disabled")
    }

    this.showHideLegalFormOther()
  }

  checkCredit(e){

    let horseId = $(e.target).val()

    if(horseId != ''){
      $.ajax({
        type: "POST",
        url: "/horses/show_partial.js",
        data: "horse_id=" + horseId + "&partial_name=coupling_card_credit&into=div_credit_count"
      }).done(function() {
        $('button[type="submit"]').prop("disabled", !$('#credit_count').hasClass('credit-available'))
        $('#div_credit_count').removeClass("d-none")
      })
    }else{
      $('#div_credit_count').addClass("d-none")
      $('button[type="submit"]').prop("disabled", false)
    }
  }

  selectStallion(e){
    this._updateSelectedStallion($(e.currentTarget))
  }

  _onInputChange(e) {
    let changedInput = e.target;
    let inputTag =changedInput.tagName
    let valueKey = $(changedInput).attr("name").replace(/\[/g, "_").replace(/\]/g, "");
    let value = ""
    switch (inputTag) {
      case "SELECT":
      case "select":
        if (valueKey == "coupling_card_mare_holder_national_number_country" || valueKey == "coupling_card_mare_holder_company_country") {
          value = $(changedInput).val()
        } else {
          value = $(changedInput).find(":selected").text()
        }
        break;
      case "input":
      case "INPUT":
          if ($(changedInput).attr("type") == "checkbox" && $(changedInput).val() == "1") {
            value = $(changedInput).is(":checked")
          } else if ($(changedInput).attr("type") == "radio") {
            value = $(changedInput).siblings("label").text()
          }
           else {
            value = $(changedInput).val()
          }
        break
      default:
        value = $(changedInput).val()
    }
    if (valueKey.length > 0) {
      if ($(changedInput).is(":checked") || $(changedInput).attr("type") != 'radio') {
        this._updateInfo(valueKey, value, changedInput)
      }
    }
  }
  _updateInfo(valueKey, value, input) {
    let container = $('*[data-card-value-bind="'+valueKey+'"]');
    let valuesArr
    //console.log("_updateInfo")
    //console.log(valueKey)
      switch(valueKey) {
        case "coupling_card_mare_id":
          this._updateHorse("coupling_card_mare", input)
          break;
        case "coupling_card_stallion_id":
          this._updateHorse("coupling_card_stallion", input)
          this._updateCouplingYears($(input).val())
          break;
        case "coupling_card_mare_name":
        case "coupling_card_mare_birth_date":
        case "coupling_card_mare_father_name":
        case "coupling_card_mare_mother_name":
        case "coupling_card_mare_microchip":
        case "coupling_card_mare_code":
          this._updateHorseManual("coupling_card_mare")
          break;
        case "coupling_card_embryo_transfer":
          this._toggleEmbryoTransfer(value);
          if (value) {
            $('[name="coupling_card[coupling_mode]"][value="5"]').attr("disabled", "disabled")
          } else {
            $('[name="coupling_card[coupling_mode]"][value="5"]').removeAttr("disabled")
          }
          break
        case "coupling_card_coupling_mode":
          this._enableDisableEmbryoTransfer(value);
        case "coupling_card_notes":
          container.html(value);
          break
        case "coupling_card_mare_holder_address_line1":
        case "coupling_card_mare_holder_address_number":
        case "coupling_card_mare_holder_address_box":
        case "coupling_card_mare_holder_address_zip":
        case "coupling_card_mare_holder_address_locality_name":
          this._updateAddress("coupling_card_mare_holder_address")
          break;
        case "coupling_card_global_coupler":
          this._updateCoupler(input);
          break;
        case "coupling_card_year":
          container.text($(input).val());
          break;
        case "coupling_card_mare_holder_asl":
          this._updateAsl(container, value)
          break;
        default:
          container.text(value);
      }
  }

  _updateCouplingYears(horseId){
    //console.log("_updateCouplingYears")
    let card_id = this.data.get("cardId")
    let partial_name = "coupling_cards/coupling_card_available_years"
    let into = "coupling_card_available_years"

    $.ajax({
      type: "POST",
      url: "/cards/show_partial.js",
      data: "card_id=" + card_id + "&stallion_id=" + horseId + "&partial_name=" + partial_name + "&into=" + into
    }).done(function(data) {
      $('[name="coupling_card[year]"]:not([type="hidden"]):checked').trigger("change")
    })
  }

  _updateCoupler(input){
    $.ajax({
      type: "POST",
      url: "/contacts/show.js",
      data: "contact_global_id=" + $(input).val() + "&partial_name=coupling_cards/coupler_card_body&into=coupler_card_body"
    })
  }

  _updateAddress(addressId){

    let line1 = $('#' + addressId + '_line1').val()
    let number = $('#' + addressId + '_number').val()
    let box = $('#' + addressId + '_box').val()

    if(box != ''){
      box = I18n.t('contacts.data_fields.default.box').toLowerCase() + ' ' + box
    }

    let zip = $('#' + addressId + '_zip').val()
    let locality_name = $('#' + addressId + '_locality_name').val()

    let addressPartOne = [line1, number, box].filter(x => x).join(' ')
    let addressPartTwo = [zip, locality_name].filter(x => x).join(' ')

    let address = (addressPartOne!=''?('<span class="d-none d-md-inline d-print-inline">' + addressPartOne + ', </span>'):'') + addressPartTwo;

    $(this.element).find('#' + addressId).html(address);
  }

  updateHorse(e){
    let horseKey = $(e.currentTarget).data("horseKey")
    let horseEncoding = $(e.currentTarget).data("horseEncoding")
    if(horseEncoding == "manual"){
      this._updateHorseManual(horseKey)
    }else{
      let input = (horseKey == "coupling_card_stalion") ? $('select[name="coupling_card[stalion_id]"]').get(0) : $('select[name="coupling_card[mare_id]"]').get(0)
      this._updateHorse(horseKey, input)
    }
  }

  _updateHorse(horseKey, input){
    this._clearHorse(horseKey)

    let valuesArr = $(input).find(":selected").text().split(" - ")

    if (valuesArr[1]) {
      $(this.element).find('*[data-card-value-bind="' + horseKey + '_code"]').html(valuesArr[1].split(" ")[0]);
    }

    $(this.element).find('*[data-card-value-bind="' + horseKey + '_name"]').html(valuesArr[0]);
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_pedigree"]').html($(input).siblings("small.text-muted").text());
  }

  _clearHorse(horseKey){
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_pedigree"]').html('');
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_name"]').html('');
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_code"]').html('');
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_microchip"]').html('');
  }

  _updateHorseManual(horseKey){
    this._clearHorse(horseKey)

    let fatherName = $('#' + horseKey + '_father_name').val()
    let motherName = $('#' + horseKey + '_mother_name').val()

    fatherName = fatherName!=''?(I18n.t("horses.by") + ' ' + fatherName) : ''
    motherName = motherName!=''?(I18n.t("horses.and") + ' ' + motherName) : ''

    let pedigree = [fatherName, motherName].filter(x => x).join(' ')

    $(this.element).find('*[data-card-value-bind="' + horseKey + '_pedigree"]').html(pedigree);
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_name"]').html($('#' + horseKey + '_name').val());
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_code"]').html($('#' + horseKey + '_code').val());
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_microchip"]').html($('#' + horseKey + '_microchip').val());
  }

  _updateSelectedStallion(stallionElt){

    $(".horses-list .list-group-item.selected").removeClass("selected")

    $(stallionElt).addClass("selected")

    $(this.stallionIdTarget).val($(stallionElt).data("stallionId"))
    
    this._updateCouplingYears($(stallionElt).data("stallionId"))

    this._updateInfo("coupling_card_stallion_name", $(stallionElt).find(".select-stallion-name").first().text());
    this._updateInfo("coupling_card_stallion_code", $(stallionElt).find(".select-stallion-code").first().text().split(" ")[0]);
    this._updateInfo("coupling_card_stallion_pedigree", $(stallionElt).find(".select-stallion-pedigree").first().text());

    this._updateCouplingYears($(stallionElt).data("stallionId"))

    let couplerSelect = $(this.globalCouplerTarget)
    let select2Options = {
      allowClear: true,
      // dropdownParent: $(this.globalCouplerTarget).parents("div.main-container, div.modal").first(),
      placeholder: $(this.globalCouplerTarget).data("select2Placeholder"),
      width: '100%',
      theme: 'material',
      selectOnClose: true,
      dropdownParent: $(".main-container"),
      language: "fr",
      templateSelection: function(r){
        if (r.label) {
          return r.label
        } else {
          return r.text
        }
      }
    }

    let ctrl = this

    if($(stallionElt).data("stallionId") != ''){
      $.ajax({
        type: "POST",
        url: "/contacts/selectable_couplers.json",
        data: "horse_id=" + $(stallionElt).data("stallionId")
      }).done(function(data) {
        select2Options.data = data.results
        $(couplerSelect).empty().select2(select2Options)
        $(couplerSelect).prop("disabled", (data.results.length == 1))

        ctrl._updateCoupler(ctrl.globalCouplerTarget)
      })
    }
  }

  showHideLegalFormOther() {
    if ($('#coupling_card_mare_holder_legal_form_id').val() == "0") {
      $('[data-card-value-bind="coupling_card_mare_holder_legal_form_id"]').addClass('d-none')
      $('[data-card-value-bind="coupling_card_mare_holder_legal_form_other"]').removeClass('d-none')
    } else {
      $('[data-card-value-bind="coupling_card_mare_holder_legal_form_other"]').addClass('d-none')
      $('[data-card-value-bind="coupling_card_mare_holder_legal_form_id"]').removeClass('d-none')
    }
  }

  _toggleEmbryoTransfer(value){
    if (value) {
      $(this.element).find(".with_embryo_transfer").removeClass("d-none")
      $(this.element).find(".without_embryo_transfer").addClass("d-none")
    } else {
      $(this.element).find(".with_embryo_transfer").addClass("d-none")
      $(this.element).find(".without_embryo_transfer").removeClass("d-none")
    }
  }

  _enableDisableEmbryoTransfer(value){
    if (value == "ICSI") {
      $('[name="coupling_card[embryo_transfer]"][value="1"]').attr("disabled", "disabled")
      this._toggleEmbryoTransfer(false)
    } else {
      $('[name="coupling_card[embryo_transfer]"][value="1"]').removeAttr("disabled")
    }
  }

  _onRowRepeat(repeatEvent) {
    if (repeatEvent.target) {
      $(repeatEvent.target).find("input").each(function(i, el){
        if ($(el).hasClass("coupling-date")) {
          let dateDataGroup = $(".form-confirm .data-group.coupling-date").first()
          let newDateDataGroup = dateDataGroup.clone()
          newDateDataGroup.find(".data-value span").attr("data-card-value-bind", $(el).attr("id"))
          dateDataGroup.after(newDateDataGroup)
        }
      })
    }
  }

  _updateAsl(container, value){
    container.text(value);
    if(value == ""){
      container.parents(".list-group-item").first().addClass('d-none');
    }else{
      container.parents(".list-group-item").first().removeClass('d-none');
    }
  }

  _onRowRemove(repeatEvent) {
    if (repeatEvent.target) {
      let removedDateInput = $(repeatEvent.target).find("input.coupling-date");
      if (removedDateInput) {
        let removeDateData = $(".form-confirm .data-group.coupling-date span[data-card-value-bind="+removedDateInput.attr("id")+"]");
        $(removeDateData).remove()
      }
    }
  }


}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["listContainer", "listContainerEmpty", "signagesContainer"];

  connect() {
    let ctrl = this

    $(this.element).on("show.bs.tab", ".nav-link", function(e){ctrl._onTabShow(e)})

    if(this._canLoad($(".nav-link.active"))){
      this._loadDocuments()
    }
  }

  _canLoad(navlink){
    let pane = $($(navlink).attr("href")).attr("id")

    return pane && (pane.startsWith("signage") || pane.startsWith("documents"))
  }

  _onTabShow(e){

    if(this._canLoad($(e.target))){
      this._loadDocuments()
    }
  }

  _loadDocuments() {
    if(this.data.get("loaded") == "true"){
      return
    }

    console.log("load documents")

    let horse_id = this.data.get("horseId")

    let ctrl = this

    $.ajax({
      type: "POST",
      url: "/documents/list.json",
      data: "id=" + horse_id + "&authenticity_token=" + $('[name="csrf-token"]')[0].content
    }).then(function (data) {
      ctrl.data.set("loaded", true)

      if(data.length > 0){

        let sign_idx = 1
        
        $.each(data, function(key, doc){

          if(doc.type_code == "SIG"){
            if (ctrl.hasSignagesContainerTarget) {
              let signTemplate = $("#document_signage_template").clone().prop("content")
              $(signTemplate).find(".document_signage_template_index").html(sign_idx)
              $(signTemplate).find(".document_signage_template_edit_link").attr("data-doc-id", doc.identification)
              $(signTemplate).find(".document_signage_template_edit_link").attr("data-doc-type", doc.type_code)
              $(signTemplate).find(".document_signage_template_preview").attr("data-document-preview-id", doc.identification)
              $(signTemplate).find(".document_signage_template_preview").attr("data-document-preview-type", doc.type_code)
              $(signTemplate).find(".document_signage_template_preview").attr("src", doc.url)
              $(signTemplate).find(".document_signage_template_link").attr("href", doc.url)
              $(ctrl.signagesContainerTarget).append(signTemplate)
              sign_idx++
            }
          }

          if (ctrl.hasListContainerTarget) {
            let docTemplate = $("#document_list_item_template").clone().prop("content")
            $(docTemplate).find(".document_list_item_template_row").attr("data-href", doc.url)
            $(docTemplate).find(".document_list_item_template_row").attr("data-doc-id", doc.identification)
            $(docTemplate).find(".document_list_item_template_filename").html(doc.filename)
            $(docTemplate).find(".document_list_item_template_type").html(doc.type)
            $(docTemplate).find(".document_list_item_template_creation_time").html(doc.creation_time)
            $(docTemplate).find(".document_list_item_template_creation_link").attr("href", doc.url)
            $(ctrl.listContainerTarget).append(docTemplate)
          }
        });

        $(ctrl.listContainerEmptyTarget).addClass("d-none")
      }else{
        $("#create_signage_button").attr("disabled", "disabled").addClass("disabled")
        $(ctrl.listContainerEmptyTarget).removeClass("d-none")
      }

      $(".dias-available").removeClass("d-none")
    }).fail(function(xhr, status, error) {

      if(xhr.status == 500){
        $(".dias-available").addClass("d-none")
        $(".dias-unavailable").addClass("d-none")
        $(".dias-error").removeClass("d-none")
      }else if(xhr.status == 503){
        $(".dias-available").addClass("d-none")
        $(".dias-error").addClass("d-none")
        $(".dias-unavailable").removeClass("d-none")
      }else{
        $(".dias-unavailable").addClass("d-none")
        $(".dias-error").addClass("d-none")
        $(".dias-available").removeClass("d-none")
      }
    }).always(function(xhr, status, error) {
      $(".dias-loading").addClass("d-none")
    })
  }

  setDeletePath(e){
    let signageId = $(e.currentTarget).attr("data-doc-id")

    let horseId = $('*[data-controller*="uppy"]').attr("data-horse-id")

    $("#deleteSignageModal").find(".delete-signage-link").attr("href", "/horses/" + horseId + "/delete_graphic_signage/" + signageId)
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

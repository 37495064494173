import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["table", "form"];

  static oldDelivrable = null

  toggleCreateDelivrable(e){

    let delivrableFormTemplate = $("#delivrable_form_template").clone().prop("content")

    let resourceGid = $(this.element).attr("data-delivrables-resource-gid")

    $(delivrableFormTemplate).find("#delivrable_resource_gid").val(resourceGid)

    $(this.formTarget).find('.delivrable-form-wrapper').html(delivrableFormTemplate)

    $(this.element).find("#add_delivrable").collapse('toggle');

    if(this.oldDelivrable){
      $('*[data-delivrables-delivrable-id="' + $(this.oldDelivrable).attr("data-delivrables-delivrable-id") + '"]').removeClass("delivrable-edit")
      $('*[data-delivrables-delivrable-id="' + $(this.oldDelivrable).attr("data-delivrables-delivrable-id") + '"]').html(this.oldDelivrable.html())
      this.oldDelivrable = null
    }
  }

  displayErrors(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    let fields = response.fields

    if(fields){
      fields.forEach(function(f){
        for (var [key, value] of Object.entries(f)){
          $(e.target).find('#' + key).addClass("is-invalid")
          $(e.target).find('#' + key).after('<div class="invalid-feedback">' + value + '</div>');
        }
      })
    }
  }

  onSubmit(e) {
    $(e.target).find(".is-invalid").removeClass("is-invalid")
    $(e.target).find(".invalid-feedback").remove()
  }

  connect() {
    this.element.addEventListener('ajax:beforeSend', this.onSubmit)
  }

  disconnect() {
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit)
  }

  cancelCreate(e){
    e.preventDefault()
    e.stopPropagation()
    $(this.formTarget).removeClass("show")
  }

  cancelEdit(e){
    e.preventDefault()
    e.stopPropagation()
    $('*[data-delivrables-delivrable-id="' + $(this.oldDelivrable).attr("data-delivrables-delivrable-id") + '"]').removeClass("delivrable-edit")
    $('*[data-delivrables-delivrable-id="' + $(this.oldDelivrable).attr("data-delivrables-delivrable-id") + '"]').html(this.oldDelivrable.html())
    this.oldDelivrable = null
  }

  submitForm(e){
    e.preventDefault()
    e.stopPropagation()
    let nativeFormEl = $(e.currentTarget).parents("form")[0]
    nativeFormEl.requestSubmit()
  }

  editDelivrable(e){

    $(".tooltip.show").remove()

    e.preventDefault()

    if($(e.currentTarget).parents(".delivrable").eq(0).hasClass("delivrable-edit")){
      return
    }

    $(e.currentTarget).parents(".delivrable").eq(0).addClass("delivrable-edit")

    if(this.oldDelivrable){
      $('*[data-delivrables-delivrable-id="' + $(this.oldDelivrable).attr("data-delivrables-delivrable-id") + '"]').removeClass("delivrable-edit")
      $('*[data-delivrables-delivrable-id="' + $(this.oldDelivrable).attr("data-delivrables-delivrable-id") + '"]').html(this.oldDelivrable.html())
      this.oldDelivrable = null
    }

    $(this.formTarget).removeClass("show")
    $(this.formTarget).find(".delivrable-form-wrapper").empty()

    let delivrable = $(e.currentTarget).parents(".delivrable").eq(0)

    let delivrableFormTemplate = $("#delivrable_form_template").clone().prop("content")

    let resourceGid = $(this.element).attr("data-delivrables-resource-gid")

    $(delivrableFormTemplate).find("#delivrable_resource_gid").val(resourceGid)

    this.oldDelivrable = delivrable.clone(true)

    $(delivrableFormTemplate).find("#delivrable_subject_id").find("option[value='" + $(this.oldDelivrable).attr("data-delivrable-subject-id") + "']").attr("selected", "selected");
    
    $(delivrableFormTemplate).find("#delivrable_reference").val($(this.oldDelivrable).attr("data-delivrable-reference"))
    
    $(delivrableFormTemplate).find("#delivrable_payed").prop("checked", $(this.oldDelivrable).attr("data-delivrable-payed") == "true")
    
    $(delivrableFormTemplate).find("#delivrable_payed_at").val($(this.oldDelivrable).attr("data-delivrable-payed-at"))

    $(delivrableFormTemplate).find("form").attr("id", "new_delivrable_" + $(this.oldDelivrable).attr("data-delivrables-delivrable-id"))
    
    $(delivrableFormTemplate).find("#cancel_button").attr("data-action", "delivrables#cancelEdit")
    $(delivrableFormTemplate).find("form").attr("data-action", "ajax:error->delivrables#displayErrors ajax:success->delivrables#displayEdited")
    $(delivrableFormTemplate).find("form").attr("action", "/delivrables/" + $(this.oldDelivrable).attr("data-delivrables-delivrable-id") + ".json")
    $(delivrableFormTemplate).find("#save_button").attr("data-action", "delivrables#submitForm")
    $(delivrableFormTemplate).find("form").prepend('<input name="_method" type="hidden" value="patch">')

    delivrable.html('<td colspan="6" class="delivrable-form-wrapper"></td>')

    delivrable.find(".delivrable-form-wrapper").html(delivrableFormTemplate)

    delivrable.addClass("delivrable-edit")
  }

  setDeletePath(e){
    console.log($("#deleteDelivrableModal").find(".delete-delivrable-link").attr("href"))
    let delivrableId = $(e.currentTarget).parents(".delivrable").eq(0).attr("data-delivrables-delivrable-id")

    $("#deleteDelivrableModal").find(".delete-delivrable-link").attr("href", "/delivrables/" + delivrableId)

    console.log($("#deleteDelivrableModal").find(".delete-delivrable-link").attr("href"))
  }

  removeDelivrable(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);
    console.log(response.id)
    if(response.id){
      $("#deleteDelivrableModal").modal("toggle");

      $('*[data-delivrables-delivrable-id="' + response.id + '"]').fadeTo(1000, 0.01, function(){ 
        $(this).slideUp(150, function() {
          $(this).remove()
          $(".tooltip.show").remove()
        }); 
      });
    }
  }

  displayEdited(e){
    this.oldDelivrable = null

    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    $.each(response, function(key, delivrable){
      let delivrableTemplate = $("#delivrable_list_item_template").clone().prop("content")
      
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrables-delivrable-id", delivrable.id)
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrable-subject-id", delivrable.subject.id)
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrable-reference", delivrable.reference)
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrable-payed", delivrable.payed)
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrable-payed-at", "")
      $(delivrableTemplate).find(".delivrable_list_item_template_subject").html(delivrable.subject.name)
      $(delivrableTemplate).find(".delivrable_list_item_template_reference").html(delivrable.reference)
      $(delivrableTemplate).find(".delivrable_list_item_template_payed").html(delivrable.payed)
      $(delivrableTemplate).find(".delivrable_list_item_template_payed_at").html(delivrable.payed_at)
      
      $('*[data-delivrables-delivrable-id="' + delivrable.id + '"]').replaceWith(delivrableTemplate)
      $('*[data-delivrables-delivrable-id="' + delivrable.id + '"]').removeClass("delivrable-edit")
    });

    this._showSnackbar(I18n.t("delivrables.flash.edit.success"))
  }

  displayAdded(e){
    this.oldDelivrable = null

    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    let ctrl = this

    $.each(response, function(key, delivrable){
      let delivrableTemplate = $("#delivrable_list_item_template").clone().prop("content")
      
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrables-delivrable-id", delivrable.id)
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrable-subject-id", delivrable.subject.id)
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrable-reference", delivrable.reference)
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrable-payed", delivrable.payed)
      $(delivrableTemplate).find(".delivrable_list_item_template_row").attr("data-delivrable-payed-at", "")
      $(delivrableTemplate).find(".delivrable_list_item_template_subject").html(delivrable.subject.name)
      $(delivrableTemplate).find(".delivrable_list_item_template_reference").html(delivrable.reference)
      $(delivrableTemplate).find(".delivrable_list_item_template_payed").html(delivrable.payed)
      $(delivrableTemplate).find(".delivrable_list_item_template_payed_at").html(delivrable.payed_at)
      
      $(ctrl.tableTarget).find('tbody tr:first').after(delivrableTemplate)
    });

    $("#no_delivrable").remove()

    $(this.formTarget).removeClass("show")

    this._showSnackbar(I18n.t("delivrables.flash.create.success"))
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

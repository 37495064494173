import { Controller } from "@hotwired/stimulus"

// src https://discourse.stimulusjs.org/t/stale-data-with-quill-editor/317

export default class extends Controller {


  connect() {
    let ctrl = this;
    $(this.element).on("show.bs.tab", ".boolean-tabs .nav-link", function(e){ctrl.showBoolTab(e)})
    $(this.element).on("hide.bs.tab", ".boolean-tabs .nav-link", function(e){ctrl.hideBoolTab(e)})
    $(this.element).find(".bool-content-toggler").each(function(i, el){
      ctrl.showOrHideFormContent($(el).find("input"))
    })
  }

  showBoolTab(e) {
    let pane = $($(e.target).attr("href"))
    pane.find("input, select").prop("disabled", false)
  }

  hideBoolTab(e) {
    let pane = $($(e.target).attr("href"))
    pane.find("input, select").prop("disabled", true)
  }

  toggleBoolContent(e){
    let target = e.target
    let targetName = $(target).attr("name")
    let elements = $("input[type='" + $(target).attr("type") + "'][name='"+targetName+"']")
    this.showOrHideFormContent(elements)
  }

  showOrHideFormContent(elements){
    let ctrl = this
    elements = $(elements);
    elements.each(function(i, btn){
      if ($(btn).is(":checked")) {
        ctrl.showFormContent($(btn).data("toggle-content"))
      } else {
        ctrl.hideFormContent($(btn).data("toggle-content"))
      }
    })
  }

  showFormContent(selector){
    console.log(selector)
   let contentContainer = $(selector)
   if (contentContainer.length > 0) {
     contentContainer.addClass("animated fadeIn").removeClass("d-none").find("input, select, radio, checkbox, textarea").prop("disabled", false)
   }
  }

  hideFormContent(selector) {
    let contentContainer = $(selector)
    if (contentContainer.length > 0) {
      contentContainer.removeClass("animated fadeIn").addClass("d-none").find("input, select, radio, checkbox, textarea").prop("disabled", true)
    }
  }


}

import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["holder", "holderEmail", "holderEmailHidden", 'detentionPlaceCode', 'detentionPlaceAddress'];

  connect() {    
    this.updateHolderEmails()
    this.setHolderAddressCopy()
  }

  setHolderAddressCopy(e) {
    let contact_global_id = $(this.holderTarget).val()

    $(this.copyHolderAddressButtonTarget).prop("disabled", contact_global_id == "")
  }

  updateHolderEmails(e) {

    let contact_global_id = $(this.holderTarget).val()
    let ctrl = this

    $(this.holderEmailTarget).empty()
    $(this.holderEmailTarget).prop("disabled", false)

    if(contact_global_id == ""){
      let option = new Option(this.holderEmailTarget.dataset.noContactSelectedPlaceholder, "", true, true);
      $(this.holderEmailTarget).append(option)

      $(this.holderEmailTarget).prop("disabled", true)

      this.setHolderEmailHidden()
    }else{
      $.ajax({
        type: "POST",
        url: "/contacts/get_emails.json",
        data: "contact_global_id=" + contact_global_id
      }).then(function(data){

        data.forEach(function(email){
          let option = new Option(email, email);
          $(ctrl.holderEmailTarget).append(option)
        })

        if(data.length == 0){
          let option = new Option(ctrl.holderEmailTarget.dataset.noEmailAvailablePlaceholder, "", true, true);
          $(ctrl.holderEmailTarget).append(option)

          $(ctrl.holderEmailTarget).prop("disabled", true)
        }else if(data.length == 1){
          $(ctrl.holderEmailTarget).prop("disabled", true)
        }

        $(ctrl.holderEmailTarget).find("option:eq(0)").prop("selected", true);

        ctrl.setHolderEmailHidden()
      })
    }

  }

  setHolderEmailHidden(e) {
    //console.log("set holder email")
    $(this.holderEmailHiddenTarget).val($(this.holderEmailTarget).val())
  }

}

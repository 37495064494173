import Inputmask from "inputmask";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field", "trigger"]

  connect() {
    if (this.data.has("def")) {
      if (this.hasTriggerTarget) {
        this.triggerChange()

      } else {
        this.setMask()
      }

    } else {

    }

  }

  setMask() {
    this._inputMask().mask(this.fieldTarget)
    $(this.fieldTarget).attr("placeholder", this.fieldTarget.inputmask.getemptymask())
  }

  unsetMask() {
    if (this.fieldTarget.inputmask) {
      this.fieldTarget.inputmask.remove()
      if (this.fieldTarget.value == this.fieldTarget.getAttribute("placeholder")) {
        this.fieldTarget.value = ""
      }
      this.fieldTarget.setAttribute("placeholder", "")
    }
  }


  triggerChange(){
    if (this.triggerOn.indexOf($(this.triggerTarget).val()) > -1) {
      this.setMask()
    } else {
      this.unsetMask()
    }
  }

  get triggerOn() {
    return this._isValidJSON(this.data.get("def")) ? Object.keys(JSON.parse(this.data.get("def"))) : this.data.get("trigger-on").split(",")
  }

  set triggerOn(arr) {
    this.data.set("trigger-on", arr.toString())
  }


  _inputMask() {
    let mask = this.data.get("def")

    if (this._isValidJSON(this.data.get("def"))) {
      mask = JSON.parse(this.data.get("def"))[$(this.triggerTarget).val()]
    }
    
    mask = mask.split(",")

    var options = {}
    if (this.data.has("placeholder")) {
      options["placeholder"] = this.data.get("placeholder")
    } else {
      options["placeholder"] = "–"
    }
    if (this.data.has("casing")) {
      options["casing"] = this.data.get("casing")
    }
    if (this.data.has("greedy")) {
      options["greedy"] = String(this.data.get("greedy")).toLowerCase() === 'true'
    }
    if (this.data.has("remove-on-submit")) {
      options["removeMaskOnSubmit"] = String(this.data.get("remove-on-submit")).toLowerCase() === 'true'
    }

    if (mask.length < 2) {
      mask = mask[0]
    } else {
      options["keepStatic"] = false
    }

    let im = Inputmask(mask, options)
    return im
  }

  _isValidJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['input', 'false', 'true', 'both'];

  connect() {
    this.switch()
  }

  switch(e) {
    let anyChecked = false
    $(this.inputTargets).each(function() {
      if($(this).is(":checked")){
        anyChecked = true
      }
    });

    if (!anyChecked) {
      this._hideContent(this.falseTargets)
      this._hideContent(this.trueTargets)
      this._hideContent(this.bothTargets)
    }else{
      if ($(this.inputTarget).is(':checked')) {
        this._showContent(this.trueTargets)
        this._hideContent(this.falseTargets)
      } else {
        this._showContent(this.falseTargets)
        this._hideContent(this.trueTargets)
      }
      
      this._showContent(this.bothTargets)
    }
  }

  _showContent(elements) {
    $.each(elements, function(key, value) {
      $(value).addClass("animated fadeIn").removeClass("d-none")
    });
  }

  _hideContent(elements) {
    $.each(elements, function(key, value) {
      $(value).removeClass("animated fadeIn").addClass("d-none")
    });
  }

}

import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  connect() {
    this.element.addEventListener('ajax:beforeSend', this.onSubmit)
  }

  onSubmit(e) {
    $(e.target).find(".is-invalid").removeClass("is-invalid")
    $(e.target).find(".invalid-feedback").remove()
  }

  setValidateApprovalTemporaryModalAction(e){
    $("#validate_approval_temporary").prop("action", "/horses/" + this.data.get("horseId") + "/approvals/" + this.data.get("id") + "/validate_temporary")
  }

  setValidateApprovalInfinitelyModalAction(e){
    $("#validate_approval_infinitely").prop("action", "/horses/" + this.data.get("horseId") + "/approvals/" + this.data.get("id") + "/validate_infinitely") 
  }

  setRefuseApprovalModalAction(e){
    $("#refuse_approval").prop("action", "/horses/" + this.data.get("horseId") + "/approvals/" + this.data.get("id") + "/refuse") 
  }

  displayErrors(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    let fields = response.fields

    if(fields){
      fields.forEach(function(f){
        for (var [key, value] of Object.entries(f)){
          //console.log(key + " " + value)
          if(key == "note_body"){
            key = "note_quill"
          }
          //console.log(e.target)
          $(e.target).find("." + key).find("input, select").addClass("is-invalid")
          $(e.target).find("." + key).find("input, select").after('<div class="invalid-feedback">' + value + '</div>');
        }
      })
    }
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['sorter', 'sorted'];


  connect() {
    //this.placeholders = new Object
    //this.contentTarget = $(this.targetTarget)
    const sorterElement = $(this.sorterTarget)
    const sortedElement = $(this.sortedTarget)
    const labelsBy = $(this.element).find(".sortable-label-by")
    const labelsDir = $(this.element).find(".sortable-label-dir")
    sorterElement.on("click", "a", function(e){
      e.preventDefault()
      let clickedLink = $(this)
      if (clickedLink.hasClass("sortable-dir")) {
        sorterElement.find(".sortable-dir").removeClass("active")
        labelsDir.text(clickedLink.find("i").text())
        clickedLink.addClass("active")
      } else if (clickedLink.hasClass("sortable-by")) {
        sorterElement.find(".sortable-by").removeClass("active")
        labelsBy.text(clickedLink.text())
        clickedLink.addClass("active")
      }
      sortedElement.addClass("animated fadeOut")
      sortedElement.load($(this).attr("href"), function(){
        sortedElement.removeClass("fadeOut")
        sortedElement.addClass("fadeIn")
      })
    })
  }


}

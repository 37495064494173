import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import XHRUpload from "@uppy/xhr-upload"
import Dashboard from "@uppy/dashboard"
import French from '@uppy/locales/lib/fr_FR';
import English from '@uppy/locales/lib/en_US';
import Dutch from '@uppy/locales/lib/nl_NL';

export default class extends Controller {

  static targets = [];

  static uppy = null

  connect(){
    const Locale = this._getLocale()

    this.uppy = new Uppy({
      locale: Locale,
      debug: false,
      autoProceed: false,
      restrictions: {
        maxFileSize: parseInt(this.data.get("maxSize")),
        maxNumberOfFiles: parseInt(this.data.get("maxFiles")),
        minNumberOfFiles: parseInt(this.data.get("minFiles")),
        allowedFileTypes: ['image/*']
      }
    })
    .use(Dashboard, {
      //trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: $('*[data-uppy-target="container"]').get(0),
      replaceTargetContent: true,
      showProgressDetails: true,
      note: I18n.t("uppy.note", { max_files: this.data.get("maxFiles"), max_size: this._bytesToSize(this.data.get("maxSize"))}),
      height: 470,
      metaFields: [
        { id: 'name', name: I18n.t("uppy.fields.name.name"), placeholder: I18n.t("uppy.fields.name.placeholder") },
        { id: 'caption', name: I18n.t("uppy.fields.caption.name"), placeholder: I18n.t("uppy.fields.caption.placeholder") }
      ],
      browserBackButtonClose: true
    })
    .use(XHRUpload, {
      endpoint: this.data.get("endpoint"),
      formData: true,
      fieldName: 'files[]'
    })

    let ctrl = this

    $("#deleteSignageModal").on("ajax:success", function(e){
      let [data, status, xhr] = e.detail;
      let response = JSON.parse(xhr.response);
      
      if(response.document_id){
        $("#deleteSignageModal").modal("hide");
        $("#uploadSignageModal").modal("hide");

        $('#horse_signages_container *[data-doc-id="' + response.document_id + '"]').closest(".card").fadeTo(1000, 0.01, function(){ 
          $(this).slideUp(150, function() {
            $(this).remove()
          }); 
        });

        $('#horse_documents_container *[data-doc-id="' + response.document_id + '"]').fadeTo(1000, 0.01, function(){ 
          $(this).slideUp(150, function() {
            $(this).remove()
          }); 
        });
      }

      ctrl._showSnackbar(I18n.t("documents.flash.destroy.signage.success"))

      ctrl.uppy.cancelAll()
    });
  }

  configureForDiasCreation(e){
    this.uppy.cancelAll()
    
    this.uppy.setMeta({ doc_id: "" })
    this.uppy.setMeta({ doc_type: "" })

    this.uppy.off('complete')
    this.uppy.on('complete', result => {
      //console.log('successful files:', result.successful)
      //console.log('failed files:', result.failed)

      if(result.successful[0]){
        let doc = result.successful[0].response.body

        let signTemplate = $("#document_signage_template").clone().prop("content")
        $(signTemplate).find(".document_signage_template_index").html($(".document_signage_template_index").length + 1)
        $(signTemplate).find(".document_signage_template_edit_link").attr("data-doc-id", doc.identification)
        $(signTemplate).find(".document_signage_template_edit_link").attr("data-doc-type", doc.type_code)
        $(signTemplate).find(".document_signage_template_preview").attr("data-document-preview-id", doc.identification)
        $(signTemplate).find(".document_signage_template_preview").attr("data-document-preview-type", doc.type_code)
        $(signTemplate).find(".document_signage_template_preview").attr("src", doc.url)
        $(signTemplate).find(".document_signage_template_link").attr("href", doc.url)
        $('*[data-documents-target="signagesContainer"]').append(signTemplate)

        let docTemplate = $("#document_list_item_template").clone().prop("content")
        $(docTemplate).find(".document_list_item_template_row").attr("data-href", doc.url)
        $(docTemplate).find(".document_list_item_template_row").attr("data-doc-id", doc.identification)
        $(docTemplate).find(".document_list_item_template_filename").html(doc.filename)
        $(docTemplate).find(".document_list_item_template_type").html(doc.type)
        $(docTemplate).find(".document_list_item_template_creation_time").html(doc.creation_time)
        $(docTemplate).find(".document_list_item_template_creation_link").attr("href", doc.url)
        $('*[data-documents-target="listContainer"]').append(docTemplate)

        $('*[data-uppy-target="container"]').parents().find(".modal.show").modal('hide');

        this._showSnackbar(I18n.t("documents.flash.create.signage.success"))

        this.uppy.cancelAll()
      }

      if(result.failed[0]){
        this._displayErrors(result.failed[0])
      }
    })

    $($(e.currentTarget).data("target")).find(".modal-title").text(I18n.t("modal.upload_signage.title"))

    $("#uploadSignageModal .delete-signage-link").addClass("d-none")
  }

  configureForDiasUpdate(e){
    this.uppy.cancelAll()
    
    let doc_id = $(e.currentTarget).data("docId")
    let doc_type = $(e.currentTarget).data("docType")

    this.uppy.setMeta({ doc_id: doc_id })
    this.uppy.setMeta({ doc_type: doc_type })

    this.uppy.off('complete')
    this.uppy.on('complete', result => {
      //console.log('successful files:', result.successful)
      //console.log('failed files:', result.failed)

      let previewContainer = $('*[data-document-preview-id="' + doc_id + '"]')

      if(result.successful[0] && previewContainer.length){
        let url = result.successful[0].uploadURL

        previewContainer.attr("src", url)

        $('*[data-uppy-target="container"]').parents().find(".modal.show").modal('hide');

        this._showSnackbar(I18n.t("documents.flash.update.signage.success"))

        this.uppy.cancelAll()
      }

      if(result.failed[0]){
        this._displayErrors(result.failed[0])
      }
    })

    $($(e.currentTarget).data("target")).find(".modal-title").text(I18n.t("modal.upload_signage.title_edit"))

    $("#uploadSignageModal .delete-signage-link").removeClass("d-none")
  }

  _displayErrors(failed){
    let body = failed.response.body

    let error = body.errors[0]
    let warning = body.warnings[0]

    console.log(error)
    console.log(warning)

    if(error){
      this.uppy.info({
        message: I18n.t("documents.dias.errors." + error.code),
        details: error.string,
      }, 'error', 10000)
    }

    if(warning){
      this.uppy.info({
        message: I18n.t("documents.dias.errors." + warning.code),
        details: warning.string,
      }, 'warning', 10000)
    }
  }

  _getLocale(){
    switch(I18n.locale){
      case "fr": return French
      case "en": return English
      case "nl": return Dutch
      default: return French
    }
  }

  _bytesToSize(bytes) {
     let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
     if (bytes == 0) return '0 Byte';
     let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
     return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formElement", "displayValue", "clear"]

  connect() {
    this.formElementTarget.addEventListener('blur', this.onBlur, true)
    this.formElementTarget.addEventListener('keypress', this.onKeyPress, true)
    this.prepareInput()
  }

  onBlur = (e) => {
    if(e.relatedTarget == this.clearTarget){
      this.clearInput(e)
    }else{
      this.hideInput(e)
    }
  }

  onKeyPress = (e) => {
    if(this._enterPressed(e)){
      e.preventDefault()
      e.stopPropagation()
      this.hideInput(e)
      return false
    }
  }

  showInput(e) {
    $(this.element).removeClass("selected")
    $(this.clearTarget).removeClass("d-none")
    $(this.displayValueTarget).addClass("d-none")
    $(this.formElementTarget).val($(this.formElementTarget).val().trim()).removeClass("d-none").focus()

    if(!$(this.formElementTarget).val()){
      $(this.formElementTarget).val(this.data.get("defaultValue"))
    }
  }

  prepareInput() {
    let input = $(this.formElementTarget)
    let display = $(this.displayValueTarget)
    let clear = $(this.clearTarget)

    clear.addClass("d-none")

    if(input.val().trim() != ""){
      if($(input).is("select")){
        display.text($(input).find("option:selected").text())
      }else{
        display.text(input.val().trim())
      }

      $(this.element).addClass("selected")
    }else{
      display.text(input.attr('placeholder'))
      $(this.element).removeClass("selected")
    }

    input.addClass("d-none")
    display.removeClass("d-none")
  }
  
  clearInput(e) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()

    let input = $(this.formElementTarget)
    let clear = $(this.clearTarget)

    input.val("")
    this.hideInput()
  }

  hideInput(e) {
    this.prepareInput()
    $(this.clearTarget).addClass("d-none")

    let event = new Event('change', { bubbles: true })
    this.formElementTarget.dispatchEvent(event)
  }

  _enterPressed(e) {
    return e.keyCode === 13
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['defaultSortBy'];

  connect() {
    if (this.hasDefaultSortByTarget) {

      let dir = this.data.get('defaultSortDirection').toLowerCase()

      if (['asc', 'desc'].includes(dir)) {
        this.asc = !(dir == 'asc')
      }

      this._doSort(this.defaultSortByTarget)
    }
  }

  // Sélection

  selectAllRows(e) {
    let selectInput = $(e.target)
    let checked = selectInput.is(":checked")
    $(this.element).find("tr th .row-selector").filter(function() {
      return !this.disabled;
    }).prop("checked", checked)
  }

  selectRow(e) {
    let row = $(e.target).parents("tr")
    let rowSelectors =  row.find(".row-selector")
    let checked = !rowSelectors.first().is(":checked")
    rowSelectors.prop("checked", checked)
    this._updateSelectAllInputs()
  }

  _updateSelectAllInputs() {
    let selectAllinputs = $(this.element).find(".all-rows-selector")
    let rowSelectors = $(this.element).find(".row-selector:not(.all-rows-selector)")
    if (rowSelectors.length == rowSelectors.filter(":checked").length) {
      selectAllinputs.prop("checked", true).prop("indeterminate", false)
    } else if (rowSelectors.filter(":checked").length == 0 ){
      selectAllinputs.prop("checked", false).prop("indeterminate", false)
    } else {
      selectAllinputs.prop("checked", false).prop("indeterminate", true);
    }

  }

  // Tri

  // adapté depuis https://stackoverflow.com/questions/3160277/jquery-table-sort/19947532#19947532
  sort(e){
    this._doSort(e.currentTarget)
  }

  _doSort(elt) {
    let table = $(elt).parents('table').eq(0)

    table.find('.sort-order').remove()
    let compFunc = null
    if($(elt).data("tableDateColumn") == true){
      compFunc = this._comparerDate($(elt).index())
    }else if($(elt).data("tableDateColumnTimestamp") == true){
      compFunc = this._comparerDateTimestamp($(elt).index())
    }else{
      compFunc = this._comparer($(elt).index())
    }
    let rows = table.find('tr:gt(0)').toArray().sort(compFunc.bind(this))
    this.asc = !this.asc
    if (!this.asc) { rows = rows.reverse() }
    if (!this.asc) {
      $(elt).append('<i class="material-icons sort-order">arrow_upward</i>');
    } else {
      $(elt).append('<i class="material-icons sort-order">arrow_downward</i>');
    }
    for (let i = 0; i < rows.length; i++){
      table.append(rows[i])
    }
  }

  _comparer(index) {
    return function(a, b) {
      let valA = this._getCellValue(a, index), valB = this._getCellValue(b, index)

      return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB, I18n.locale, {numeric: true, sensitivity: 'base'})
    }
  }

  _comparerDateTimestamp(index) {
    return function(a, b) {
      let valA = this._getCellValueTimestamp(a, index), valB = this._getCellValueTimestamp(b, index)

      return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB, I18n.locale, {numeric: true, sensitivity: 'base'})
    }
  }

  _comparerDate(index) {
    return function(a, b) {
      let valA = this._getCellValue(a, index), valB = this._getCellValue(b, index)

      if(valA == '' && valB == ''){
        return 0
      }else if(valA == ''){
        return -1
      }else if(valB == ''){
        return 1
      }

      let separator = '/'
      let order = [2, 1, 0];

      if(I18n.locale == 'nl'){
        separator = '-'
      }else if(I18n.locale == 'en'){
        separator = '-'
        order = [0, 1, 2];
      }

      let dateArrA = valA.split(separator);
      let dateArrB = valB.split(separator);

      const add = (a, b) => a + b
      valA = order.map(x => dateArrA[x]).reduce(add)
      valB = order.map(x => dateArrB[x]).reduce(add)

      return valA - valB
    }
  }

  _getCellValue(row, index){
    return $(row).children('th, td').eq(index).text()
  }

  _getCellValueTimestamp(row, index){
    return $(row).children('th, td').eq(index).data("tableDateTimestampValue")
  }

  // Filtre

  // adapté depuis https://github.com/dstull/stimulus-demo/blob/master/app/javascript/controllers/hello_controller.js
  typeFilter(e) {
    if (this._enterPressed(e)) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
    $('tr.filterable-row').show().find('input').removeAttr('disabled');
    this._applyFilter($(e.currentTarget).val().trim())
  }

  _applyFilter(filterValue) {
    if (filterValue.length)
      this._filterRows(filterValue)
  }

  _filterRows(filterValue) {
    $.expr[':'].contains = $.expr.createPseudo((arg) => {
      return (elem) => {
        return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0
      }
    })
    $('tr.filterable-row').hide().find('input').prop("disabled", true)
    $("tr.filterable-row td:contains('" + filterValue + "')").parent().show().find('input').removeAttr('disabled');
  }

  disableSubmit(e) {
    if (this._enterPressed(e)) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }

  _enterPressed(e) {
    return e.keyCode === 13
  }

  // Lien

  linkRow(e) {
    var isLink = $(e.srcElement).parents("a, button, .btn").length > 0;
    if (!isLink) {
      var linkTarget = $(e.currentTarget).data("target")
      if (linkTarget) {
        window.open($(e.currentTarget).data("href"), linkTarget)
      } else {
        window.location = $(e.currentTarget).data("href");
      }

    }
  }
}

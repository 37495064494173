import { Controller } from "@hotwired/stimulus"

import { changeFormFieldIndex }  from "../helpers.js"

export default class extends Controller {

  static targets = ["source", "destination"]

  connect() {
    this._refreshConditionalContent()
  }

  repeat() {
    const sourceElement = $(this.sourceTarget)
    const newElement = sourceElement.clone()

    newElement.html(changeFormFieldIndex(newElement.html(), this.sourceTarget.dataset.repeatableAttributes))

    // L'option formatSubmit dans datepicker_controller.js rajoute un input hidden
    // On ne veut pas le copier, sinon il sera présent 2 fois (suite à l'initialisation du datepicker_controller)
    let datePickerEltsToRemove = newElement.find('[data-controller="datepicker"]').nextAll().remove()
    // On supprime également le data-value, lié à l'option formatSubmit
    newElement.find('[data-controller="datepicker"]').removeAttr("data-value")

    if(this.data.get("addTo") == 'top'){
      newElement.insertAfter($(this.destinationTarget))
    }else{
      newElement.insertBefore($(this.destinationTarget))
    }

    newElement.addClass("fadeIn animated").removeClass("fadeOut").css("display", "")
    newElement.find("input, select").not(":hidden, :checkbox, :radio").val(null).change("")
    newElement.find("textarea").val("").change("")
    newElement.find(":checkbox, :radio").prop("checked", false)

    newElement.find(".repeatable-must-be-reset input").find(":hidden").val(0)
    newElement.find(".repeatable-must-be-reset select").val(null).change("")

    //console.log(newElement.find(".ql-editor"))
    //console.log(newElement.find(".ql-editor").get(0))
    if(newElement.find(".ql-editor").length){
      newElement.find(".ql-editor").get(0).innerHTML = ''
    }

    newElement.removeAttr('data-select2-id');
    newElement.find("option:selected").removeAttr("selected");
    newElement.find("option").removeAttr('data-select2-id');

    newElement.find("select").prop('disabled', false);

    newElement.find("[data-default-value!=''][data-default-value]").each(function(idx){
      $(this).val($(this).data("defaultValue"))
    });

    //$(newElement).trigger("repeatable.add")
    let repeatableEvent = new CustomEvent('repeatable.add', { bubbles: true })
    newElement[0].dispatchEvent(repeatableEvent)
    newElement.trigger("repeatable.add")
    this._refreshConditionalContent()
    let firstInput = newElement.find("input:visible, select:visible").first()
    if (firstInput.length > 0) {
      firstInput.trigger("focus")
    }
  }

  remove(event) {
    const parentElement = $(event.currentTarget).parents(".repeatable")
    parentElement.removeClass("fadeIn").addClass("fadeOut animated")
    parentElement.on("webkitAnimationEnd animationEnd", function(event) {
      $(this).css("display", "none")
    });
    if(this.data.get("disable") == 'true'){
      $(parentElement).find("option:selected").removeAttr("selected");
      $(parentElement).find("option").removeAttr('data-select2-id');
      console.log($(parentElement).find("select"))
      $(parentElement).find("select").prop('disabled', true);
    }else{
      parentElement.find("input.destroy").val("1")
    }
    let repeatableEvent = new CustomEvent('repeatable.remove', { bubbles: true })
    parentElement[0].dispatchEvent(repeatableEvent)
    $(parentElement).trigger("repeatable.remove")
    this._refreshConditionalContent()
  }

  _refreshConditionalContent() {
    let isMul =  ($(this.element).find(".repeatable:not(.fadeOut)").length > 1);
    if (isMul) {
      $(this.element).addClass("repeatable-is-mul").removeClass("repeatable-isnot-mul");
    } else {
      $(this.element).addClass("repeatable-isnot-mul").removeClass("repeatable-is-mul");
    }
  }

}

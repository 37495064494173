import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['pplBreedership', 'pplOwnership', 'pplFirstHolder', 'birthCountry', 'couplingCard', 'father', 'mother', 'dnaHorseHair', 'dnaHorseHairInput', 'dnaHorseHairReceivalDateInput']

  connect(){
    $(this.couplingCardTarget).on("select2:selecting", function(e) {
      $(e.target).empty().trigger('change');
    })
  }

  onAddBreedership(){

  }

  onSetBreedership(e){
    var breederRowIndex = 0
    if (e.target) {
      var breederRow = $(e.target).parents(".form-row.repeatable" )
      var breederRowIndex = $(this.pplBreedershipTargets).index(breederRow)
    }
    var selectedData = $(e.target).select2('data')[0]

    if (breederRowIndex == 0) {
      var ownerRow =  $(this.pplOwnershipTargets)[breederRowIndex]
      
      while (ownerRow == undefined) {
        $(this.pplOwnershipTargets).last().siblings('.repeat-btn').first().trigger("click")
        ownerRow =  $(this.pplOwnershipTargets).last()
      }
    
      let targetInput = $(ownerRow).find(".horse_ownerships_global_owner select")

      if (targetInput.val().length < 1) {
        if (targetInput.find("option[value='" + selectedData.id + "']").length) {
          targetInput.val(selectedData.id)
        } else {
          var newOption = new Option(selectedData.label, selectedData.id, true, true);
          targetInput.append(newOption)
        }

        let event = new Event('change', { bubbles: true })
        targetInput[0].dispatchEvent(event);
      }

      let birthCountryInput = $(this.birthCountryTarget)

      if(birthCountryInput.val() == "" && selectedData.country_code != undefined){
        birthCountryInput.val(selectedData.country_code)

        let event = new Event('change', { bubbles: true })
        birthCountryInput[0].dispatchEvent(event);
      }
      
    }
  }

  onSetOwnership(e) {

    if (this.hasPplFirstHolderTarget && $(this.pplFirstHolderTarget).val() == "") {
      let targetInput = $(this.pplFirstHolderTarget)
      let selectedData = $(e.target).select2('data')[0]
      if (selectedData.label == undefined) {selectedData.label = selectedData.text}

      if (targetInput.find("option[value='" + selectedData.id + "']").length) {
        targetInput.val(selectedData.id)
      } else {
        var newOption = new Option(selectedData.label, selectedData.id, true, true);
        targetInput.append(newOption)
      }
      let event = new Event('change', { bubbles: true })
      
      targetInput[0].dispatchEvent(event);
    }
  }

  onSetCouplingCard(e) {

    if ($(e.target).val() == '') {

      $(this.fatherTarget).prop('disabled', false)
      $(this.motherTarget).prop('disabled', false)
      $('#horse_father_id').val('')
      $('#horse_mother_id').val('')

    } else {

       if (this.hasFatherTarget) {
        
        let stallion = null
        if ($(this.couplingCardTarget).select2('data')[0]) {
          stallion = $(this.couplingCardTarget).select2('data')[0].stallion;
        }

        if (stallion != null) {

          let targetInput = $(this.fatherTarget)
          let selectedData = stallion
          if (selectedData.label == undefined) {selectedData.label = selectedData.text}

          if (targetInput.find("option[value='" + selectedData.id + "']").length) {
            targetInput.val(selectedData.id)
          } else {
            var newOption = new Option(selectedData.label, selectedData.id, true, true);
            targetInput.append(newOption)
          }
          $('#horse_father_id').val(selectedData.id)

          let event = new Event('change', { bubbles: true })
          
          targetInput[0].dispatchEvent(event);

          targetInput.trigger({
              type: 'select2:selecting',
              params: {
                  args: {
                    data: stallion
                  }
              }
          });

          targetInput.prop('disabled', true)

        } else {

          $(this.fatherTarget).prop('disabled', false)

        }

      }

      if (this.hasMotherTarget) {

        let mare = null
        if ($(this.couplingCardTarget).select2('data')[0]) {
          mare = $(this.couplingCardTarget).select2('data')[0].mare;
        }

        if (mare != null) {

          let targetInput = $(this.motherTarget)
          let selectedData = mare
          if (selectedData.label == undefined) {selectedData.label = selectedData.text}

          if (targetInput.find("option[value='" + selectedData.id + "']").length) {
            targetInput.val(selectedData.id)
          } else {
            var newOption = new Option(selectedData.label, selectedData.id, true, true);
            targetInput.append(newOption)
          }
          $('#horse_mother_id').val(selectedData.id)

          let event = new Event('change', { bubbles: true })
          
          targetInput[0].dispatchEvent(event);

          targetInput.trigger({
              type: 'select2:selecting',
              params: {
                  args: {
                    data: mare
                  }
              }
          });

          targetInput.prop('disabled', true)

        } else {

          $(this.motherTarget).prop('disabled', false)

        }

      }

    }

  }

  setDnaHorseHairReceivalDate(e) {
    if ($(this.dnaHorseHairInputTarget).is(":checked") && $(this.dnaHorseHairReceivalDateInputTarget).val() == "") {
      $(this.dnaHorseHairReceivalDateInputTarget).pickadate('picker').set('select', new Date().toISOString().slice(0, 10), { format: I18n.t("date.formats.mat_datepicker") })
    }
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  formSubmit(e) {
    e.preventDefault();
    let targetId = this.data.get("target")
    if (targetId) {
      let form = document.getElementById(targetId)
      if (form) {
        $(form).submit();
      }
    }
  }

  linkTo(e) {
    e.preventDefault();
    let delayTime = this.data.get("delay")
    let href = this.element.getAttribute("href")
    if (delayTime) {
      setTimeout(function (){
        location.href =  href
      }, delayTime);
    } else {
      location.href= href
    }
  }




  classToggler(e) {
    e.preventDefault();
    var targetId = this.element.dataset.targetId
    if (targetId) {
      var el = document.getElementById(targetId)
      if (el) {
        el.classList.toggle("show");
      }
    } else {
      for (const el of document.getElementsByClassName(this.element.dataset.targetClass)) {
        el.classList.toggle("show");
      }
    }
  }


  eventDispatch(e) {
    e.stopPropagation();
    e.preventDefault();
    this._initEvent()
    document.dispatchEvent(this.btnEvent)
    // TODO permettre la définition de cible pour l'event plutot que de le faire sur le document.
  }


  eventDispatchOLD() {

    var targetEvent = new CustomEvent(this.data.get(event))
    var targetId = this.element.dataset.targetId
    var targetClass = this.element.dataset.targetClass
    if (targetId) {
      var el = document.getElementById(targetId)
      if (el) {
        el.dispatchEvent(targetEvent);
      }
    } else if(targetClass) {
      for (const el of document.getElementsByClassName(targetClass)) {
        el.dispatchEvent(targetEvent);
      }
    } else {
      document.dispatchEvent(targetEvent);
    }
  }

  _initEvent() {
    let eventType = this.data.get("eventType")
    let eventDetails = JSON.parse(this.data.get("eventDetails"))
    this.btnEvent = new CustomEvent(eventType, {"detail": eventDetails});
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['target'];

  connect() {
    $(this.targetsTarget).collapse({
      parent: this.element,
      toggle: true
    })
  }

  toggle(e) {
    let triggerElement = $(e.target);
    let targetElement = $($(e.target).data("collapse-target"));
    if (targetElement.length > 0) {
      targetElement.collapse('toggle')
      triggerElement.toggleClass('collapsed')
    }
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['step', 'stepcontent'];

  connect() {
    //$(this.stepcontentTargets).addClass("d-none")
    if (!this.data.has("step")) {
      this.data.set("step", 0)
    }
    $(this.element).find(".step").each(function(stepIndex, step){
      let errorCount = $(step).find(".is-invalid:not(.btn)").filter(":input").length
      if ( errorCount > 0) {
        $(step).parents('[data-controller="stepper"]').find(`[data-stepper-step=${stepIndex}]`).addClass("is-invalid")
      } else {
        $(step).parents('[data-controller="stepper"]').find(`[data-stepper-step=${stepIndex}]`).removeClass("is-invalid")
      }
    })
    let firstInvalidStep = $(this.element).find(".stepper.is-invalid").first()
    if (firstInvalidStep.length > 0) {
      this.data.set("step", $(firstInvalidStep).data("stepper-step"));
    }
    this.setStep(this.data.get('step'))
    //const navtabsKey = "navTabs_"+this.data.get("key")
    //$(this.element).find(".nav-tabs .nav-link, .nav-item .nav-link").on("click", function(e) {
    //  e.preventDefault()
    //  localStorage.setItem(navtabsKey, $(this).data("navtabs-value"))
    //  console.log($(this).tab())
    //  $(this).tab('show')
    //})
    //if (localStorage.getItem(navtabsKey)) {
    //  const activeTab = $(this.element).find("[data-navtabs-value="+localStorage.getItem(navtabsKey)+"]").first()
    //  if (activeTab) {
    //    activeTab.tab("show")
    //  }
    //}

  }

  activateStepper(e) {
    e.preventDefault();
    let step = $(e.currentTarget).data("stepper-step")
    this.setStep(step)
  }

  setStep(step) {
    this.activateStep(step)
    this.showStepContent(step)
    this.data.set("step", step)
  }




  activateStep(step) {
    if (step > 0) {
      $(this.stepTargets.slice(0, step)).removeClass("active").addClass("done")
    }
    if ($(this.stepTargets[step]).length > 0) {
      $(this.stepTargets[step]).removeClass("done").addClass("active")
    }
    $(this.stepTargets.slice(step+1, this.stepTargets.length )).removeClass("active done")
  }

  showStepContent(step) {
    $(this.stepcontentTargets).addClass("d-none").removeClass("fadeInLeft fadeInRight animated")
    if ($(this.stepcontentTargets[step]).length > 0) {
      $(this.stepcontentTargets[step]).removeClass("d-none")
      if (this.data.get("step") < step) {
        $(this.stepcontentTargets[step]).addClass("fadeInRight animated")
      } else if (this.data.get("step") > step) {
        $(this.stepcontentTargets[step]).addClass("fadeInLeft animated")
      }
    }
  }



}

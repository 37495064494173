import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['input', 'content'];

  connect() {
    this.switch()
  }

  switch(e) {
    if ($(this.inputTarget).is(':checked')) {
      this._showContent()
    } else {
      this._hideContent()
    }
  }

  _showContent() {
    $(this.contentTarget).slideDown()
    if (this.data.get("aside")) {
      $(this.data.get("aside")).addClass("animated fadeIn").removeClass("d-none")
    }
  }

  _hideContent() {
    $(this.contentTarget).slideUp()
    if (this.data.get("aside")) {
      $(this.data.get("aside")).removeClass("animated fadeIn").addClass("d-none")
    }
  }


}

import { Controller } from "@hotwired/stimulus"
import { InitBehaviours }  from "../initializer.js"

export default class extends Controller {
  connect() {
    const _this = this
    this.childContainerTemplate = this.element.content
    // Add an event listener
    document.addEventListener("childContent.show", function(e) {
      
      let link = e.detail.content

      if (link == undefined && Array.isArray(e.detail) && e.detail[0].content) {
        link = e.detail[0].content
      }

      window.open(link, "_self");

      //_this.loadChildContent(e.detail); // Prints "Example of an event"

    });
    this.placeholders = new Object
    $(this.element).find(".child-container").attr("data-child-content-origin", window.location.href)
  }

  loadChildContent(detail) {
    this.contentType = detail.type || "other";
    this._initChildContainer(detail.type);
    //this._openChildContainer(detail.title);
    this._openChildContainer("");
    this.contentID = Date.now();
    let stateObj = {type: "childContent", childContent: {path: detail.content, id: this.contentID}};
    history.pushState(stateObj, detail.title, detail.content);
    this._loadAndShowContentWithPlaceHolder(detail.content, detail.placeholder);
    setTimeout(function() {
      $(".expandable.expanded").removeClass("expanded")
    }, 300);

  }


  _loadContentTo(contentHolder) {
    let container = $(this.childContainer.firstElementChild)
    const childContentElement = container.find(".child-content")
    if (detail.placeholder && !childContentElement.hasClass("is-placeholder")) {
      childContentElement.load("/template/placeholder-"+detail.placeholder, function(){
        childContentElement.addClass("is-placeholder").load(detail.content)
      })
      } else {

      }
    }

  _loadAndShowContentWithPlaceHolder(contentPath ,placeholderKey){
    if (placeholderKey) {
      if (!this.placeholders[placeholderKey]) {
        let placeholderUrl = "/template/placeholder-"+placeholderKey;
        fetch(placeholderUrl)
          .then(response => response.text())
          .then(html => {
            this.placeholders[placeholderKey] = html
            this._addContent(this.placeholders[placeholderKey], true)
            this._loadAndShowContent(contentPath)
        })
      } else {
        this._addContent(this.placeholders[placeholderKey], true)
        this._loadAndShowContent(contentPath)
      }
    } else {
      this._loadAndShowContent(contentPath)
    }
  }

  _loadAndShowContent(contentPath){
    fetch(contentPath, {"credentials": "include", "headers": {'X-Requested-With':"XMLHttpRequest"}})
      .then(response => response.text())
      .then(html => {
        this._addContent(html, false)
      })
  }

    _loadAndShowPlaceholder(placeholderKey) {
      if (placeholderKey) {

        if (!this.placeholders[placeholderKey]) {
          let placeholderUrl = "/template/placeholder-"+placeholderKey;
          fetch(placeholderUrl)
            .then(response => response.text())
            .then(html => {
              this.placeholders[placeholderKey] = html
              this._addContent(this.placeholders[placeholderKey], true)
              //childContentElement.html(this.placeholders[placeholderKey]);
          })
      } else {
        this._addContent(this.placeholders[placeholderKey], true)
        //childContentElement.html(this.placeholders[placeholderKey]);
      }
    }
  }

  _addContent(ContentHtml, isPlaceHolder=false){
    let container = $(this.childContainer);
    let childContentElement = container.find(".child-content.placeholder-content").first()
    if (childContentElement.length < 1) {
      container.find(".child-content").last().removeClass("animated zoomIn fadeInLeft")
      childContentElement = container.find(".child-content").last().clone()
      container.find(".child-content").last().addClass("d-none")
      childContentElement.addClass("zoomIn animated").html("")
      container.append(childContentElement)
    }
    childContentElement.html(ContentHtml)
    $(childContentElement).attr("data-child-content-id", this.contentID)
    if (isPlaceHolder) {
      childContentElement.addClass("placeholder-content")
    } else {
      childContentElement.removeClass("placeholder-content")
      InitBehaviours(childContentElement)
    }
    if (container.find(".child-content").length > 1) {
      container.find(".btn-back").removeClass("d-none")
    } else {
      container.find(".btn-back").addClass("d-none")
    }
  }


  _openChildContainer(title) {
    let container = $(this.childContainer)
    container.find(".child-container-title").html($("<h1></h1>").text(title))
    if (!container.hasClass("show")) {
      setTimeout(function() {
        container.addClass("show animated fadeIn")
      }, 200);
    }
  }

  _initChildContainer(childType){
      let existingContainer = document.getElementById(this.containerId)
      if (existingContainer) {
        this.childContainer = existingContainer
      } else {
        // Trigger de l'ouvertture du child container => update de la classe du body
        $("body").addClass("has-child-content")
        this.childContainer = document.importNode(this.childContainerTemplate, true);
        this.childContainer.firstElementChild.id = this.containerId
        document.querySelector("body").appendChild(this.childContainer);
        this.childContainer = document.getElementById(this.containerId)
      }
  }

  get containerId() {
    return "child-container-"+this.contentType
  }

}

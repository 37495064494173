import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["recipientFilter"];

  connect() {
    
  }

  exportBulk(e) {

  	e.preventDefault();

  	$(e.currentTarget).attr('disabled', true);

  	$.ajax({
  		method: "POST",
		url: '/passports/bulk',
		xhrFields: {
			responseType: 'blob'
		},
		data: $('#new_bulk').serialize() + "&export=",
		success: function (result) {
		  	var a = document.createElement('a');
			var url = window.URL.createObjectURL(result);
			a.href = url;
			a.download = 'passeports-' + parseInt(Date.now()/1000) + '.csv';
			document.body.append(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(url);
			window.location.reload();
		},
		error: function (error) {
			console.log(error);
			window.location.reload();
		}
	});

  }

  setRecipientFilter(e){
    $(this.recipientFilterTarget).val($(e.currentTarget).text())

    let event = new Event('change', { bubbles: true })
    this.recipientFilterTarget.dispatchEvent(event)
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['input', 'togglable'];

  connect() {

    let ctrl = this

    $(this.inputTargets).each(function() {

      $(this).on("change", function(e){

        if($(e.target).is(':checked')) {
          ctrl._showContent($("[data-toggle-ref='" + $(e.target).data('toggleRef') + "']"))
          ctrl._hideContent($(ctrl.togglableTargets).not("[data-toggle-ref='" + $(e.target).data('toggleRef') + "']"))
        }      

      })

    });

    $(this.inputTargets).filter(':checked').trigger('change')

  }

  _showContent(elements) {
    $.each(elements, function(key, value) {
      $(value).prop('disable', false).removeClass("d-none")
    });
  }

  _hideContent(elements) {
    $.each(elements, function(key, value) {
      $(value).addClass("d-none").find('input, select, textarea').val('').prop('disable', true)
    });
  }

}

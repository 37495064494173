function InitBehaviours(container=null){
  if (container == null) {
    container = $("body").first()
  } else {
    container = $(container)
  }

  container.find(".custom-control .collection_radio_buttons, .custom-control .collection_check_boxes").addClass("custom-control-label")
  container.on("click", ".clickable[href]", function(){
    location.href = $(this).attr("href")
  })
  container.on("click", ".clickable[href] a, .clickable[href] button", function(e){
    e.stopPropagation();
  })

  container.find('[data-toggle="tooltip"]').tooltip()
  container.find('[data-hover="tooltip"]').tooltip()
  //$('[data-toggle="popover"]').popover()
  //$('[data-hover="popover"]').popover()

  //container.find('[data-toggle="tab-link"]').on("click", function(e){
  //  $($(this).attr("href")).tab("show").blur()
  //});


  container.find(".has-navdrawer").on("show.md.navdrawer", ".navdrawer", function(e){

    container.addClass("has-navdrawer-shown")
  }).on("hide.md.navdrawer", ".navdrawer", function(e){
    container.removeClass("has-navdrawer-shown")
  })

  container.find(".custom-checkbox-collapse input:checked").parents(".custom-checkbox").css("display", "block")

  container.find(".collapsable-label").on("click", function(){
    $(this).parents(".custom-checkbox-collapse").toggleClass("show")
  })



}

export { InitBehaviours }

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect() {
    let ctrl = this
    
    $(this.checkboxTargets).on('click', function() {
      $(ctrl.checkboxTargets).not(this).prop('checked', false);
    });

    $(this.element).on("repeatable.add", ".repeatable", function(e){ctrl._onRowRepeat(e)})
  }

  _onRowRepeat(repeatEvent) {

    if(repeatEvent.target){
      let ctrl = this

      $(repeatEvent.target).find('*[data-unique-checkbox-target="checkbox"]').on('click', function() {
        $(ctrl.checkboxTargets).not(this).prop('checked', false);
      });
    }
  }
}

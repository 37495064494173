import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
  }

  exportBulk(e) {
  	e.preventDefault();

  	$(e.currentTarget).attr('disabled', true);

  	$.ajax({
  		method: "POST",
		url: '/prime_requests/bulk',
		xhrFields: {
			responseType: 'blob'
		},
		data: $('#new_bulk').serialize() + "&export=",
		success: function (result) {
		  	var a = document.createElement('a');
			var url = window.URL.createObjectURL(result);
			a.href = url;
			a.download = 'prime-requests-' + parseInt(Date.now()/1000) + '.csv';
			document.body.append(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(url);

			// window.location.reload();

			document.querySelectorAll("[data-bulk-selection-target='selectable']").forEach(el => el.checked = false);
			let params = new URLSearchParams(location.search);
			params.delete('bulk_selected[]');
			window.location.search = params.toString();
		},
		error: function (error) {
			console.log(error);
			window.location.reload();
		}
	});

  }

  exportAll(e) {
  	e.preventDefault();

  	$(e.currentTarget).attr('disabled', true);

  	$.ajax({
  		method: "POST",
		url: '/prime_requests/bulk',
		xhrFields: {
			responseType: 'blob'
		},
		data: $('#new_bulk').serialize() + "&export_all=",
		success: function (result) {
		  	var a = document.createElement('a');
			var url = window.URL.createObjectURL(result);
			a.href = url;
			a.download = 'prime-requests-' + parseInt(Date.now()/1000) + '.csv';
			document.body.append(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(url);

			// window.location.reload();

			document.querySelectorAll("[data-bulk-selection-target='selectable']").forEach(el => el.checked = false);
			let params = new URLSearchParams(location.search);
			params.delete('bulk_selected[]');
			window.location.search = params.toString();
		},
		error: function (error) {
			console.log(error);
			window.location.reload();
		}
	});

  }

  closeList(e) {
  	e.preventDefault();

  	let message = I18n.t("horse/prime_request.actions.confirm_close_list")

  	message = "Êtes-vous sûr de vouloir cloturer la liste ?"

  	if (confirm(message)) {
  		$(e.currentTarget).attr('disabled', true);

	  	$.ajax({
	  		method: "POST",
				url: '/prime_requests/close',
				xhrFields: {
					responseType: 'blob'
				},
				data: "&close=",
				success: function (result) {
				  	var a = document.createElement('a');
					var url = window.URL.createObjectURL(result);
					a.href = url;
					a.download = 'prime-requests-' + parseInt(Date.now()/1000) + '.csv';
					document.body.append(a);
					a.click();
					a.remove();
					window.URL.revokeObjectURL(url);

					// window.location.reload();

					document.querySelectorAll("[data-bulk-selection-target='selectable']").forEach(el => el.checked = false);
					let params = new URLSearchParams(location.search);
					params.delete('bulk_selected[]');
					window.location.search = params.toString();
				},
				error: function (error) {
					console.log(error);
					window.location.reload();
				}
			});
  	}
  }

}

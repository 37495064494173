import { Controller } from "@hotwired/stimulus"

export default class extends Controller {


  copyFields(e){

      let triggerEl = $(e.target)
      let fromGroupKey = triggerEl.data("fieldCopyFrom")
      let toGroupKey = triggerEl.data("fieldCopyTo")
      let fromGroup = $(this.element).find("[data-field-copy-group-key='"+fromGroupKey+"']")
      let toGroup = $(this.element).find("[data-field-copy-group-key='"+toGroupKey+"']")

      let validationController = this.application.getControllerForElementAndIdentifier(this.element, "form-validation")

      fromGroup.each(function(i, group){
        $(group).find("[data-field-copy-key]").each(function(j, field) {
          let toField = toGroup.find("[data-field-copy-key='"+$(field).data("field-copy-key")+"']")
          let changeEvent = new Event('change', { bubbles: true })
          if (toField.length > 0) {

            if($(field).attr("type") == "radio" || $(field).attr("type") == "checkbox"){
              toField.prop("checked", $(field).prop("checked"))
              //let event = new Event('change', { bubbles: true })
              toField.get(0).dispatchEvent(changeEvent)
            }else if($(field).is("select")){

              if($(field).hasClass("select2-hidden-accessible")){

                let data = $(field).select2('data')[0]
                
                if(data && $(toField).data('select2-remote')){
                  toField.empty()

                  if(data.label == undefined){
                    data.label = data.text
                  }
                  
                  let newOption = new Option(data.label, data.id, true, true);
                  toField.append(newOption)

                  // update value
                  toField.trigger({
                      type: 'select2:select',
                      params: {
                          data: data
                      }
                  });

                  let formGroup = $(field).parents(".form-group").first()
                  let helpEl = formGroup.find(".text-muted.form-text")
                  let toFormGroup = $(toField).parents(".form-group").first()
                  let toHelpEl = toFormGroup.find(".text-muted.form-text")

                  if (helpEl.length == 1 && toHelpEl.length == 0) {
                    toFormGroup.append(helpEl.clone())
                  }
                }
              }else{
                if(toField.find("option[value='" + $(field).val() + "']").length == 0){
                  toField.append($('<option>', {
                    value: $(field).val(),
                    text: $(field).val()
                  }));
                }
              }

              //toField.val($(field).val()).trigger("change")
              toField.val($(field).val())
              toField.get(0).dispatchEvent(changeEvent)
              validationController.validateField(toField.get(0))
            }else{
              //toField.val($(field).val()).trigger("change")
              toField.val($(field).val())
              toField.get(0).dispatchEvent(changeEvent)



              //if(validationController && $(field).val() != ''){
                validationController.validateField(toField.get(0))
              //}
            }

          }
        })
      })

    //$(this.fieldTargets).filter("[data-field-copy-group='1']").each(function (i, field){
    //  if ($(field).data("field-copy-src").length > 0) {
    //    let srcField = $($(field).data("field-copy-src"))
    //    if (srcField.length > 0) {
    //      $(field).val(srcField.val())
    //    }
    //  }
    //})

  }

}

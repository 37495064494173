import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['input', 'label'];

    connect() {
      //console.log("Connect Custom File InputController")
      this.setLabel()
    }

    setLabel() {
      let filePath = this.inputTarget.value;
      let labelValue = ""
      if (filePath.length > 0) {
        labelValue = filePath.split(/[\\/]/g).pop()
      } else {
        labelValue = this.inputTarget.getAttribute("placeholder")
      }
      this.labelTarget.textContent = labelValue
    }

}

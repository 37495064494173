import { Controller } from "@hotwired/stimulus"
import Quill from "quill";

// src https://discourse.stimulusjs.org/t/stale-data-with-quill-editor/317

export default class extends Controller {
  static targets = ['editor','input'];

  connect() {
    const toolbarOptions = [
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }, { 'background': [] }]
    ]
    this.quill = new Quill(this.editorTarget, {
      modules: {
          toolbar: toolbarOptions
      },
      theme: 'bubble'
    });
    this.quill.on("text-change", e => {
      this.textChange();
    });
 }

 textChange(){
    this.inputTarget.value = this.quill.root.innerHTML;
    $(this.inputTarget).trigger("change")
 }
}

function escapeRegExp(str) {
  //src : http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

function changeFormFieldIndex(html, attributes, index){
	index = typeof index !== 'undefined' ? index : (new Date().getTime());
	attributes = escapeRegExp(attributes)
	var regex1 = new RegExp(attributes+"\\]\\[\\d+\\]","g");
	var regex2 = new RegExp(attributes+"_\\d+","g");
	html = html.replace(regex1, attributes+"]["+index+"]")
	html = html.replace(regex2, attributes+"_"+index)
	return html
}

export { changeFormFieldIndex }

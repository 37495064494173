import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['pager', 'paged'];

  connect() {
    const pagerElement = $(this.pagerTarget)
    const pagedElement = $(this.pagedTarget)
    pagerElement.on("click", "a", function(e){
      e.preventDefault()
      let clickedLink = $(this)
      pagedElement.addClass("animated fadeOut")
      pagedElement.load($(this).attr("href"), function(){
        pagedElement.removeClass("fadeOut")
        pagedElement.addClass("fadeIn")
      })
    })
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var _this = this
    this.bannerTemplate = this.element.content
    // Add an event listener
    this.element.addEventListener("banner.show", function(e) {
      _this.showBanner(e.detail); // Prints "Example of an event"
    });

  }

  triggerBanner(data) {
    data= {"message": "Test"}
    var event = new CustomEvent("banner.show", data);
    this.element.dispatchEvent(event);
  }

  showBanner(data) {
    var newBanner = document.importNode(this.bannerTemplate, true);

    //["webkitTransitionEnd", "transitionEnd"].forEach(function(en) {
    //  newbanner.firstElementChild.addEventListener(en, function(e){
    //    setTimeout(function() {
    //      newbanner.firstElementChild.classList.toggle("show");
    //    }, 6e3);
    //  })
    //});
    var banner = newBanner.firstElementChild
    //var bannerBody = banner.getElementsByClassName("banner-body")[0]
    //bannerBody.textContent = data.message
    banner.getElementsByClassName("banner-text")[0].textContent = data.message;
    var bannerId = "banner-"+(new Date().getTime());
    banner.id = bannerId

    for (const btn of banner.getElementsByClassName("close")) {
      btn.dataset.targetId = bannerId
    }

    if (data.action) {
      // TODO : créer action
    } else {
    //  banner.removeChild(banner.getElementsByClassName("banner-btn")[0]);
    }
    document.querySelector("body").appendChild(newBanner);

    //setTimeout(function() {
      banner.classList.add("show");
      var mainContainer = document.getElementById("main-container");
      $(mainContainer).css("padding-top", banner.clientHeight+8);
    //}, 200);

    banner.addEventListener("banner.hide", function(e) {
      this.classList.remove("show")
      banner.classList.add("hidding")
      var mainContainer = document.getElementById("main-container");
      $(mainContainer).css("padding-top", 0);
    });

  }

  close() {

  }



  toggle() {
    //ToggleClass(this.element, "show")
    if (this.element.classList) {
      this.element.classList.toggle("show");
    } else {
        // For IE9
        var classes = this.element.className.split(" ");
        var i = classes.indexOf("show");

        if (i >= 0)
            classes.splice(i, 1);
        else
            classes.push("show");
        this.element.className = classes.join(" ");
    }
  }


}

// src/controllers/hello_controller.js
import { Controller } from "@hotwired/stimulus"

require('select2')()


export default class extends Controller {


  connect() {
    let ctrl = this

    var select2Options = {
      placeholder: this.data.get("placeholder")||"",
      width: '100%',
      theme: 'material',
      selectOnClose: true,
      dropdownParent: $(".main-container"),
      language: I18n.locale,
      templateSelection: function(r){
        if (r.label) {
          return r.label
        } else {
          return r.text
        }
      }
    }

    select2Options.dropdownParent = $(this.element).parents("div.main-container, div.modal").first()


    if ($(this.element).prop('multiple')) {
      select2Options.allowClear = false
    } else {
      select2Options.allowClear = true
    }

    if (this.data.get("tags")) {
      select2Options.tags = true
    }

    if (this.data.get("remote")) {
      let filters = this.data.get("filter")
      select2Options.ajax = {
        url: this.data.get("source"),
        dataType: this.data.get("dataType")||"json",
        data: function (params) {
          let filteringField = $(this).data("select2-filtering-field")
          let filteringFieldFilterName = $(this).data("select2-filtering-field-filter-name")

          if(filteringField && filteringFieldFilterName){
            filters = '{ "' + filteringFieldFilterName + '" : "' + $("#" + filteringField).val() + '"}'
          }

          var query = {
            q: params.term,
            f: filters
          }
          return query;
        }
      }
      select2Options.minimumInputLength = this.data.get("minLength")||3
    } else {

    }

    var existingSelect2 = this.element.parentNode.getElementsByClassName("select2-container")
    while(existingSelect2.length > 0){
      this.element.parentNode.removeChild(existingSelect2[0]);
    }


      $(this.element).select2(select2Options)

      let filteringField = this.data.get("filtering-field")
      if(filteringField && $("#" + filteringField).val() == ""){
        this.clearValue(this.element.id)
        this.disableField(this.element.id)
      }

      $(this.element).on('select2:select', function(e) {
        if($(ctrl.element).closest("[data-controller*='repeatable'")){
          $(ctrl.element).closest("[data-repeatable-target='source'").find(".destroy").first().val("false")
        }
        let event = new Event('change', { bubbles: true })
        e.target.dispatchEvent(event)
      })
      // Hack pour ne pas selectionner le champs lorsqu'on le vide * deselectionne
      $(this.element).on('select2:unselecting', function(e) {
        if($(ctrl.element).closest("[data-controller*='repeatable'")){
          $(ctrl.element).closest("[data-repeatable-target='source'").find(".destroy").first().val("1")
        }
        let opts = $(this).data('select2').options;
        opts.set('disabled', true);
        setTimeout(function() {
            opts.set('disabled', false);
        }, 1);
        let formGroup = $(e.target).parents(".form-group")
        let helpEl = formGroup.find(".text-muted.form-text")
        console.log(formGroup)
        console.log(helpEl)
        if (helpEl.length > 0) {
          helpEl.remove()
        }

        let event = new Event('change', { bubbles: true })
        e.target.dispatchEvent(event)
      });

      // Affiche le hint de la selection si il y en a un.
      if (!this.data.get("skiphint")) {
        $(this.element).on("select2:selecting", function(e) {

            let hint = e.params.args.data.hint;
            if (hint != undefined) {
              let formGroup = $(e.target).parents(".form-group").first()
              let helpEl = formGroup.find(".text-muted.form-text")

              if (helpEl.length < 1) {
                helpEl = $("<small></small>").addClass("text-muted form-text")
                formGroup.append(helpEl)
              }
              helpEl.text(hint)
            }
        })
      }

      // Action secondaire affichée sur le selecteur
      let secondaryAction = this.data.get("secondary-action")
      if (secondaryAction && secondaryAction.length > 0) {
        secondaryAction = JSON.parse(secondaryAction)
        let actionBtn = $("<a></a>").addClass("select2-secondary_action").attr("href", secondaryAction.url).attr("target", "_blank")
        actionBtn.html($("<i></i>").addClass("material-icons").html(secondaryAction.icon))
        if (secondaryAction.tooltip) {
          actionBtn.attr("data-original-title", secondaryAction.tooltip)
          actionBtn.attr("data-hover", "tooltip")
          actionBtn.attr("data-placement", "top")
          actionBtn.tooltip()
        }
        $(this.element).on('select2:open', function(e){
          let dropDown = $("body").find(".select2-search--dropdown")
          dropDown.append(actionBtn)
        })
      }

    //}


  }

  changeFilteredField(){
    let filteredField = this.data.get("filtered-field")

    if(!filteredField){
      return
    }

    this.clearValue(filteredField)

    if($(this.element).val() == ""){
      this.disableField(filteredField)
    }else{
      this.enableField(filteredField)
    }
  }

  clearValue(field){
    $("#" + field).val(null).trigger('change')
  }

  enableField(field){
    $("#" + field).prop('disabled', false);
  }

  disableField(field){
    $("#" + field).prop('disabled', true);
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    
  }

  showPassportNumber(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);
    
    if(response.id){
      let number = response.number

      $(e.currentTarget).replaceWith(number)

      this._showSnackbar(I18n.t("horse/passport.flash.create.success"))

      this._updatePassport()
    }
  }

  updateExportComplete(e){
    let [xhr, status] = e.detail;
    
    if(xhr.status == 200){
      this._showSnackbar(I18n.t("horse/passport.flash.update_export.success"))
    }else{
      this._showSnackbar(I18n.t("horse/passport.flash.update_export.fail"))
    }
  }

  showEditPassportCreatedOnHorseIdAtModal(e) {
    e.preventDefault()

    $('#editPassportCreatedOnHorseIdAtModal #update_created_on_horseid_at').attr('action', this.data.get("editCreatedAtOnHorseIdAtUrl"))

    if (this.data.get("editCreatedAtOnHorseIdAtValue") != "") {
      $('#editPassportCreatedOnHorseIdAtModal #created_in_horse_id_at_input').first().pickadate('picker').set('select', this.data.get("editCreatedAtOnHorseIdAtValue"), { format: I18n.t("date.formats.mat_datepicker") })
    }

    $("#editPassportCreatedOnHorseIdAtModal").modal("show");
  }

  /*
  showPassportLink(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);
    
    if(response.id){
      let link = '<a href="#" class="text-black" data-toggle="modal" data-target="#passportModal">' + response.number + '</a>'

      $(e.currentTarget).replaceWith(link)

      this._showSnackbar(I18n.t("horse/passport.flash.create.success"))

      this._updatePassport()
    }
  }
  */
  
  _updatePassport(){
    let horse_id = this.data.get("horseId")
    let partial_name = "horses/passport"
    let into = this.data.get("updateInto")

    $.ajax({
      type: "POST",
      url: "/horses/show_partial.js",
      data: "horse_id=" + horse_id + "&partial_name=" + partial_name + "&into=" + into
    })
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

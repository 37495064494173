require('es6-promise').polyfill();
import browserUpdate from 'browser-update';
browserUpdate({
  required: {e:-4,f:-3,o:-3,s:-1,c:-3},
  insecure:true,
  unsupported:true,
  reminder: 0,
  reminderClosed: 0,
  api:2020.09,
  text_in_fr: {
    'msg':'Votre navigateur web ({brow_name}) n\'est pas à jour.<br/>',
    'msgmore': 'Un navigateur à jour est indispensable pour utiliser SireCloud.<br/>Mettez également à jour votre navigateur pour plus de sécurité et de rapidité.',
    'bupdate': 'Mettre à jour',
    'bignore': 'Ignorer',
    'remind': 'You will be reminded in {days} days.',
    'bnever': 'Never show again'
  },
  no_permanent_hide: true
})
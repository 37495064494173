import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["form"];

  static oldNote = null

  toggleCreateNote(e){
    $(this.element).find("#add_note").collapse('toggle');

    if(this.oldNote){
      $('*[data-notesnoteid="' + $(this.oldNote).attr("data-notesnoteid") + '"]').removeClass("note-edit")
      $('*[data-notesnoteid="' + $(this.oldNote).attr("data-notesnoteid") + '"]').html(this.oldNote.html())
      this.oldNote = null
    }
  }

  displayErrors(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    let fields = response.fields

    if(fields){
      fields.forEach(function(f){
        for (var [key, value] of Object.entries(f)){
          if(key == "note_body"){
            key = "note_quill"
          }

          $(e.target).find('#' + key).addClass("is-invalid")
          $(e.target).find('#' + key).after('<div class="invalid-feedback">' + value + '</div>');
        }
      })
    }
  }

  onSubmit(e) {
    $(e.target).find(".is-invalid").removeClass("is-invalid")
    $(e.target).find(".invalid-feedback").remove()
  }

  connect() {

    this.element.addEventListener('ajax:beforeSend', this.onSubmit)

    let annotatedGid = this.data.get("annotatedGid")
    let size = this.data.get("size")

    let ctrl = this

    $.ajax({
      type: "POST",
      url: "/notes/list.json",
      data: "annotated_gid=" + annotatedGid + "&size=" + size + "&authenticity_token=" + $('[name="csrf-token"]')[0].content
    }).then(function (data) {

      if(data.length > 0){

        $.each(data, function(key, note){
          let noteTemplate = $("#note_template").clone().prop("content")
          if(!note.can_edit){
            $(noteTemplate).find(".note_template_edit").attr("data-action", "").addClass("d-none")
          }
          $(noteTemplate).find(".note_template_container").attr("data-notesnoteid", note.id)
          $(noteTemplate).find(".note_template_type").html(note.note_type)
          $(noteTemplate).find(".note_template_author").html(note.author)
          $(noteTemplate).find(".note_template_privacy").html(note.privacy)
          $(noteTemplate).find(".note_template_written_at").html(note.written_at)
          $(noteTemplate).find(".note_template_body").html(note.body)
          $(ctrl.element).find('#actual_notes').append(noteTemplate)
        });

        $(ctrl.element).find("#actual_notes").removeClass("d-none")

        if(data.length == size){
          $(ctrl.element).find("#show_more_notes").removeClass("d-none")
        }

        ctrl._showSnackbar(I18n.t("notes.flash.list.success"))
      }

      $(ctrl.element).find("#dummy_notes").addClass("d-none")
    }).fail(function(){
      ctrl._showSnackbar(I18n.t("notes.flash.list.fail"))
    });
  }

  disconnect() {
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit)
  }

  showMoreNotes(e){
    e.preventDefault()
    
    $(this.element).find("#dummy_notes").removeClass("d-none")

    let annotatedGid = this.data.get("annotatedGid")
    let after_id = $(this.element).find("#actual_notes .note").last().attr("data-notesnoteid")

    let ctrl = this

    $.ajax({
      type: "POST",
      url: "/notes/list.json",
      data: "annotated_gid=" + annotatedGid + "&after_id=" + after_id + "&authenticity_token=" + $('[name="csrf-token"]')[0].content
    }).then(function (data) {

      if(data.length > 0){

        $.each(data, function(key, note){
          let noteTemplate = $("#note_template").clone().prop("content")
          if(!note.can_edit){
            $(noteTemplate).find(".note_template_edit").attr("data-action", "").addClass("d-none")
          }
          $(noteTemplate).find(".note_template_container").attr("data-notesnoteid", note.id)
          $(noteTemplate).find(".note_template_type").html(note.note_type)
          $(noteTemplate).find(".note_template_author").html(note.author)
          $(noteTemplate).find(".note_template_privacy").html(note.privacy)
          $(noteTemplate).find(".note_template_written_at").html(note.written_at)
          $(noteTemplate).find(".note_template_body").html(note.body)
          $(ctrl.element).find('#actual_notes').append(noteTemplate)
        });

        $(ctrl.element).find("#actual_notes").removeClass("d-none")
        
        ctrl._showSnackbar(I18n.t("notes.flash.list.success"))
      }else{
        ctrl._showSnackbar(I18n.t("notes.flash.list.no_more_notes"))
      }

      $(ctrl.element).find("#dummy_notes").addClass("d-none")
      $(ctrl.element).find("#show_more_notes").addClass("d-none")

    }).fail(function(){
      ctrl._showSnackbar(I18n.t("notes.flash.list.fail"))
    });
  }

  cancelCreate(e){
    e.preventDefault()
    e.stopPropagation()
    $(this.formTarget).removeClass("show")
  }

  deleteNote(e){
    e.preventDefault()
    e.stopPropagation()

    let nativeFormEl = $(e.currentTarget).parents("form")

    $(nativeFormEl).attr("data-action", "ajax:error->notes#displayErrors ajax:success->notes#removeNote")
    $(nativeFormEl).find('input[name="_method"]').replaceWith('<input name="_method" type="hidden" value="delete">')
    nativeFormEl[0].requestSubmit()
  }

  removeNote(e){
    $('*[data-notesnoteid="' + $(this.oldNote).attr("data-notesnoteid") + '"]').remove()
    this.oldNote = null

    this._decreaseCounters()

    if($(this.element).find("#actual_notes .note").length == 0){
      $(this.element).find("#show_more_notes").addClass("d-none")
    }

    this._showSnackbar(I18n.t("notes.flash.destroy.success"))
  }

  cancelEdit(e){
    e.preventDefault()
    e.stopPropagation()
    $('*[data-notesnoteid="' + $(this.oldNote).attr("data-notesnoteid") + '"]').removeClass("note-edit")
    $('*[data-notesnoteid="' + $(this.oldNote).attr("data-notesnoteid") + '"]').html(this.oldNote.html())
    this.oldNote = null
  }

  submitForm(e){
    e.preventDefault()
    e.stopPropagation()
    let nativeFormEl = $(e.currentTarget).parents("form")[0]
    nativeFormEl.requestSubmit()
  }

  editNote(e){

    e.preventDefault()

    if($(e.currentTarget).parents(".note").eq(0).hasClass("note-edit")){
      return
    }

    $(e.currentTarget).parents(".note").eq(0).addClass("note-edit")

    if(this.oldNote){
      $('*[data-notesnoteid="' + $(this.oldNote).attr("data-notesnoteid") + '"]').removeClass("note-edit")
      $('*[data-notesnoteid="' + $(this.oldNote).attr("data-notesnoteid") + '"]').html(this.oldNote.html())
      this.oldNote = null
    }

    $(this.formTarget).removeClass("show")

    let note = $(e.currentTarget).parents(".note").eq(0)

    this.oldNote = note.clone(true)

    let form = $(this.formTarget).children().first().clone()

    $(form).find("#note_note_type").find("option[value='" + note.find(".note_template_type").html() + "']").attr("selected", "selected");
    
    $(form).find("#note_privacy option").each(function() {
      if($(this).text() == note.find(".note_template_privacy").html()) {
        $(this).attr("selected", "selected");
      }else{
        $(this).attr("selected", false);
      }
    });

    $(form).find("form").attr("id", "new_note_" + $(this.oldNote).attr("data-notesnoteid"))
    $(form).find("#note_body").html(note.find(".note_template_body").html())
    $(form).find("#note_quill").html(note.find(".note_template_body").html())
    $(form).find("#cancel_button").attr("data-action", "notes#cancelEdit")
    $(form).find("form").attr("data-action", "ajax:error->notes#displayErrors ajax:success->notes#displayEdited")
    $(form).find("form").attr("action", "/notes/" + $(this.oldNote).attr("data-notesnoteid") + ".json")
    $(form).find("#save_button").attr("data-action", "notes#submitForm")
    $(form).find("form").prepend('<input name="_method" type="hidden" value="patch">')

    $(form).find("#delete_button").removeClass("d-none")

    note.html($(form).html())

    note.addClass("note-edit")
  }

  displayEdited(e){
    this.oldNote = null

    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    $.each(response, function(key, note){
      let noteTemplate = $("#note_template").clone().prop("content")
      if(!note.can_edit){
        $(noteTemplate).find(".note_template_edit").attr("data-action", "").addClass("d-none")
      }
      $(noteTemplate).find(".note_template_container").attr("data-notesnoteid", note.id)
      $(noteTemplate).find(".note_template_type").html(note.note_type)
      $(noteTemplate).find(".note_template_author").html(note.author)
      $(noteTemplate).find(".note_template_privacy").html(note.privacy)
      $(noteTemplate).find(".note_template_written_at").html(note.written_at)
      $(noteTemplate).find(".note_template_body").html(note.body)
      
      $('*[data-notesnoteid="' + note.id + '"]').replaceWith(noteTemplate)
      $('*[data-notesnoteid="' + note.id + '"]').removeClass("note-edit")
    });
  }

  displayAdded(e){
    this.oldNote = null

    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    let ctrl = this

    $.each(response, function(key, note){
      let noteTemplate = $("#note_template").clone().prop("content")
      if(!note.can_edit){
        $(noteTemplate).find(".note_template_edit").attr("data-action", "").addClass("d-none")
      }
      $(noteTemplate).find(".note_template_container").attr("data-notesnoteid", note.id)
      $(noteTemplate).find(".note_template_type").html(note.note_type)
      $(noteTemplate).find(".note_template_author").html(note.author)
      $(noteTemplate).find(".note_template_privacy").html(note.privacy)
      $(noteTemplate).find(".note_template_written_at").html(note.written_at)
      $(noteTemplate).find(".note_template_body").html(note.body)
      
      $(ctrl.element).find('#actual_notes').prepend(noteTemplate)

      ctrl._increaseCounters()
    });

    $(this.formTarget).removeClass("show")

    $(this.element).find('#actual_notes').removeClass("d-none")

    this._showSnackbar(I18n.t("notes.flash.create.success"))
  }

  _increaseCounters(){
    $('*[data-notes-counter-annotated-gid="' + this.data.get("annotatedGid") + '"]').each(function(){
      $(this).text(parseInt($(this).text()) + 1)
    })
  }

  _decreaseCounters(){
    $('*[data-notes-counter-annotated-gid="' + this.data.get("annotatedGid") + '"]').each(function(){
      $(this).text(Math.max(0, parseInt($(this).text()) - 1))
    })
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

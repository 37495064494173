import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect () {
    this.element.setAttribute('novalidate', true)
    this.element.addEventListener('blur', this.onBlur, true)
    //$(this.element).find().on('change', ".radio_buttons", this.onBlur)
    this.element.addEventListener('submit', this.onSubmit)
    this.element.addEventListener('ajax:beforeSend', this.onSubmit)
  }

  disconnect () {
    this.element.removeEventListener('blur', this.onBlur)
    this.element.removeEventListener('submit', this.onSubmit)
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit)
  }

  onBlur = (event) => {

    this.validateField(event.target)
  }

  //onSubmit = (event) => {
  //  if (!this.validateForm()) {
  //    event.preventDefault()
  //    this.firstInvalidField.focus()
  //  }
  //}

  validateForm () {
    let isValid = true
    // Not using `find` because we want to validate all the fields
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
    })
    return isValid
  }

  validateField (field) {
    if (field.tagName == "SPAN")
      field = $(field).parents(".select").find("select")[0]
    if (field.length < 1 || !this.shouldValidateField(field))
      return true
    const isValid = field.checkValidity()
    field.classList.toggle('is-invalid', !isValid)
    this.refreshErrorForInvalidField(field, isValid)
    return isValid
  }

  shouldValidateField(field) {
    return  !field.disabled && !['file', 'reset', 'submit', 'button'].includes(field.type) && this.hasFormValidation(field)
  }

  refreshErrorForInvalidField (field, isValid) {
    this.removeExistingErrorMessage(field)
    if (!isValid)
      this.showErrorForInvalidField(field)
    let errorEvent =  new CustomEvent("form.validation.checked", {detail: {field: field, message: field.validationMessage}, bubbles: true})
    field.dispatchEvent(errorEvent);
  }

  removeExistingErrorMessage (field) {
    const fieldContainer = field.closest('.form-group')
    if(!fieldContainer)
      return;
    const existingErrorMessageElement = fieldContainer.querySelector('.invalid-feedback')
    if (existingErrorMessageElement)
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
  }

  showErrorForInvalidField (field) {
    const fieldContainer = field.closest('.form-group')
    if (!fieldContainer) {
        field.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
    } else {
      fieldContainer.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
    }
  }

  buildFieldErrorHtml (field) {
    return `<div class="invalid-feedback">${field.validationMessage}</div>`
  }

  get formFields () {
    return Array.from(this.element.elements)
  }

  get firstInvalidField () {
    if (hasFormValidation(field)) {
      return this.formFields.find(field => !field.checkValidity())
    } else {
      return null;
    }

  }


  checkStepperStep(e) {
    let step = e.currentTarget
    let stepIndex = $(step).parents(".stepper-steps").find(".step").index(step)
    let stepper =   $(step).parents('[data-controller="stepper"]').find(`[data-stepper-step=${stepIndex}]`)
    if ($(step).find(".is-invalid:not(.btn)").filter(":input").length > 0) {
      stepper.addClass("is-invalid")
    } else {
      stepper.removeClass("is-invalid")
    }
  }

  hasFormValidation(el){
     if (!el) {
       el = document.createElement( 'input' )
     }
     return (typeof el.checkValidity == 'function');
  }


}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['isEmptyMessage', 'item'];

  connect() {
    
  }

  itemRemoved(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);

    if(response.id){
      $(e.currentTarget).addClass('disabled').prop('disabled', true)

      let ctrl = this

      $(e.currentTarget).parents("[data-export-list-target='item']").first().fadeTo(1000, 0.01, function(){ 
        $(this).slideUp(150, function() {
          $(this).remove();
          if(ctrl.itemTargets.length == 0) {
            $(ctrl.isEmptyMessageTarget).removeClass('d-none')
          }
        }); 
      });

      this._showSnackbar(I18n.t("export_list_items.flash.remove_" + response.item_type.toLowerCase() + "_from_list.success"))
    }

    $(".tooltip.show").remove()
  }

  itemNotRemoved(e){
    $(".tooltip.show").remove()

    this._showSnackbar(I18n.t("export_list_items.flash.remove_from_list.fail"))
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    let formHasChanged = false;
    let preventWarning = false;

    $(this.element).on('change', "*", function (e) {
      formHasChanged = true;
    });

    $(this.element).on('submit', function() {
     preventWarning = true;
    });

    $(this.element).on("click", ".prevent_waning", function() {
      preventWarning = true;
    })

    $(document).ready(function () {
      window.onbeforeunload = function (e) {
          if (formHasChanged && !preventWarning) {
              var message = I18n.t('views.form_warning'), e = e || window.event;
              if (e) {
                  e.returnValue = message;
              }
              return message;
          }
      }
    });
  }

}

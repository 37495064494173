import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    
  }

  replaceWithCBCID(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);
    
    if(response.violations && response.violations.length > 0){
      let [data, status, xhr] = e.detail;
      let response = JSON.parse(xhr.response);
      
      let violations = $.map(response.violations, function(value, index) {
        return value;
      });

      this._showSnackbar(I18n.t("identifications.horseid.contact_creation_error", { error: violations.join(", ") }))
    } else if(response.errors){
      let [data, status, xhr] = e.detail;
      let response = JSON.parse(xhr.response);
      
      let errors = $.map(response.errors, function(value, index) {
        return value;
      });

      this._showSnackbar(I18n.t("identifications.horseid.contact_creation_error", { error: errors.join(", ") }))
    } else {
      if (response.cbc_id) {
        $(e.currentTarget).replaceWith(response.cbc_id)
      } else if (response.horseid_idtmp) {
        $(e.currentTarget).parent().replaceWith(I18n.t("horse_id.contact_pending_validation", { id: response.horseid_idtmp }))
      }
    }
  }

  replaceWithUpdatedCBCID(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);
    
    if(response.violations && response.violations.length > 0){
      let [data, status, xhr] = e.detail;
      let response = JSON.parse(xhr.response);
      
      let violations = $.map(response.violations, function(value, index) {
        return value;
      });

      this._showSnackbar(I18n.t("horse_id.contact_error_checking_cbc_id", { error: violations.join(", ") }))
    } else if(response.errors){
      let [data, status, xhr] = e.detail;
      let response = JSON.parse(xhr.response);
      
      let errors = $.map(response.errors, function(value, index) {
        return value;
      });

      this._showSnackbar(I18n.t("horse_id.contact_error_checking_cbc_id", { error: errors.join(", ") }))
    } else {
      if (response.cbc_id_updated) {
        $("#currentCBCId").replaceWith(response.cbc_id)
      } else {
        this._showSnackbar(I18n.t("horse_id.contact_cbc_id_still_valid"))
      }
    }
  }

  replaceWithUpdatedHorseCBCIDAndUELN(e){
    let [data, status, xhr] = e.detail;
    let response = JSON.parse(xhr.response);
    
    if(response.violations && response.violations.length > 0){
      let [data, status, xhr] = e.detail;
      let response = JSON.parse(xhr.response);
      
      let violations = $.map(response.violations, function(value, index) {
        return value;
      });

      this._showSnackbar(I18n.t("horse_id.horse_error_checking_cbc_id_ueln", { error: violations.join(", ") }))
    } else if(response.errors){
      let [data, status, xhr] = e.detail;
      let response = JSON.parse(xhr.response);
      
      let errors = $.map(response.errors, function(value, index) {
        return value;
      });

      this._showSnackbar(I18n.t("horse_id.horse_error_checking_cbc_id_ueln", { error: errors.join(", ") }))
    } else {
      if (response.cbc_id != null && response.cbc_id != '') {
        $("#linkUpdateUeln").removeClass("d-none")
      }
      
      if (response.cbc_id_or_ueln_updated) {
        $("#currentCBCId").replaceWith(response.cbc_id)
        $("#currentUELN").replaceWith(response.ueln)
      } else {
        this._showSnackbar(I18n.t($(e.currentTarget).data("i18nKey")))
      }
    }
  }

  createContact(e){
    e.preventDefault()
    $(".tooltip.show").remove()

    let contactGlobalId = $(e.currentTarget).data("contactGlobalId")
    let ctrl = this;
    let target = e.currentTarget

    $(target).addClass("disabled")

    $.ajax({
      type: "POST",
      dataType: "json",
      url: "/contacts/send_to_horse_id",
      data: "contact_global_id=" + contactGlobalId,
      success: function(data){
        if(data.violations && data.violations.length > 0){
          let violations = $.map(data.violations, function(value, index) {
            return value;
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_creation_error", { error: violations.join(", ") }))

          $(target).removeClass("disabled")
        } else if(data.errors){
          let errors = $.map(data.errors, function(value, index) {
            return value;
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_creation_error", { error: errors.join(", ") }))

          $(target).removeClass("disabled")
        }else{
          $(target).parents("tr").first().fadeTo(1000, 0.01, function(){ 
            $(this).slideUp(150, function() {
              $(this).remove(); 
            }); 
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_creation_success", { cbc_id: data.cbc_id }))
        }
      },
      error: function(data){
        ctrl._showSnackbar(I18n.t("identifications.horseid.contact_creation_unavailable"))

        $(target).removeClass("disabled")
      }
    });
  }

  updateContact(e){
    e.preventDefault()
    $(".tooltip.show").remove()

    let contactGlobalId = $(e.currentTarget).data("contactGlobalId")
    let ctrl = this;
    let target = e.currentTarget

    $(target).addClass("disabled")

    $.ajax({
      type: "POST",
      dataType: "json",
      url: "/contacts/send_to_horse_id",
      data: "contact_global_id=" + contactGlobalId,
      success: function(data){
        if(data.violations && data.violations.length > 0){
          let violations = $.map(data.violations, function(value, index) {
            return value;
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_update_error", { error: violations.join(", ") }))

          $(target).removeClass("disabled")
        } else if(data.errors){
          let errors = $.map(data.errors, function(value, index) {
            return value;
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_update_error", { error: errors.join(", ") }))

          $(target).removeClass("disabled")
        }else{
          $(target).parents("tr").first().fadeTo(1000, 0.01, function(){ 
            $(this).slideUp(150, function() {
              $(this).remove(); 
            }); 
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_update_success"))
        }
      },
      error: function(data){
        ctrl._showSnackbar(I18n.t("identifications.horseid.contact_update_unavailable"))

        $(target).removeClass("disabled")
      }
    });
  }

  ignoreUpdateContact(e){
    e.preventDefault()
    $(".tooltip.show").remove()

    let contactGlobalId = $(e.currentTarget).data("contactGlobalId")
    let ctrl = this;
    let target = e.currentTarget

    $(target).addClass("disabled")

    $.ajax({
      type: "POST",
      dataType: "json",
      url: "/contacts/ignore_update_horse_id",
      data: "contact_global_id=" + contactGlobalId,
      success: function(data){
        if(data.violations && data.violations.length > 0){
          let violations = $.map(data.violations, function(value, index) {
            return value;
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_ignore_update_error", { error: violations.join(", ") }))

          $(target).removeClass("disabled")
        } else if(data.errors){
          let errors = $.map(data.errors, function(value, index) {
            return value;
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_ignore_update_error", { error: errors.join(", ") }))

          $(target).removeClass("disabled")
        }else{
          $(target).parents("tr").first().fadeTo(1000, 0.01, function(){ 
            $(this).slideUp(150, function() {
              $(this).remove(); 
            }); 
          });

          ctrl._showSnackbar(I18n.t("identifications.horseid.contact_ignore_update_success"))
        }
      }
    });
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['filter', 'filtered', 'form'];


  connect() {

  }

  sendForm() {
    this._updateSorterLabel()
    this.formTarget.requestSubmit()
  }

  onSend(event) {
    $(this.filteredTarget).addClass("fadeOut animated")
    $(this.filterTarget).find(".dropdown-menu").removeClass("show")
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail;
    $(this.filteredTarget).html(xhr.response).removeClass("fadeOut").addClass("fadeIn animated")
  }

  _updateSorterLabel() {
    $(this.element).find(".filterable-label").text(this._filtersAsString())
  }

  _filtersAsString() {
    // Serialisation du form + filter pour ne garder que les champs filters[] avec une valeur définie
    let formFields = $(this.formTarget).serializeArray().filter(f => f.name.substring(0,7) == "filters" && f.value.length > 0)
    let results = new Array()
    $.each(formFields, function(i, field){
      let filterName = field.name.match(/filters\[(.*)\]/)[1]
      switch (filterName) {
        case "sexe":
          results.push(I18n.t("horses.values.sexe."+field.value))
          break;
        case "year":
          results.push(field.value)
          break;
        default:
          results.push(field.value)
      }
    });
    return results.join(" | ");
  }

}

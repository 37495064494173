import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['breeder', 'owner', 'co_breeder', 'co_owner', 'holder', 'operator', 'stalion', 'mare', 'detentionPlaceCodeInput', 'noDetentionPlaceCode', 'withDetentionPlaceCode', 'noDetentionPlaceCodeInput', 'detentionPlaceSelection', 'detentionPlaceDataWrapper', 'detentionPlaceData', 'detentionPlaceOperatorData', 'holdingPlaceData', 'isDetentionPlaceOperator', 'operatorName', 'operatorCBCId', 'operatorNameDisplay', 'operatorCBCIdDisplay', 'copyOperatorAddressButton', 'registerDetentionPlace', 'birthDate', 'detentionSince', 'submitButton'];
  
  detentionPlaceInvalidCodeHandler(event) {
    $("#birth_registration_identification_type_2").prop('checked', false).attr('disabled', 'disabled')
    $("#birth_registration_identification_type_4").prop('checked', false).attr('disabled', 'disabled')
    this.showAndEnableDetentionPlaceDataWrapper()
  }

  detentionPlaceValidCodeHandler(event) {
    $("#birth_registration_identification_type_2").removeAttr('disabled')
    $("#birth_registration_identification_type_4").removeAttr('disabled')
    this.hideAndDisableDetentionPlaceDataWrapper()
  }

  hideAndDisableDetentionPlaceDataWrapper(e) {
    $(this.detentionPlaceSelectionTarget).addClass("d-none")
    $(this.detentionPlaceDataWrapperTarget).addClass("d-none")
    $(this.noDetentionPlaceCodeInputTarget).val("false").removeAttr('disabled')
  }

  showAndEnableDetentionPlaceDataWrapper(e) {
    $(this.detentionPlaceDataWrapperTarget).removeClass("d-none")
    $(this.noDetentionPlaceCodeInputTarget).val("true").removeAttr('disabled')
    $(this.noDetentionPlaceCodeInputTarget).trigger("change")
  }

  connect() {
    if (this.hasDetentionPlaceCodeInputTarget && this.hasSubmitButtonTarget) {
      document.addEventListener("detention-place-valid-code", this.detentionPlaceValidCodeHandler.bind(this))
      document.addEventListener("detention-place-invalid-code", this.detentionPlaceInvalidCodeHandler.bind(this))
    }

    if (this.detentionPlaceCodeInputTarget.value == '') {
      const event = new CustomEvent("detention-place-invalid-code")
      document.dispatchEvent(event)
    }

    let crtl = this

    if (this.hasNoDetentionPlaceCodeTarget) {
      $(this.noDetentionPlaceCodeTarget).find('a').first().on('click', function(e) {
        e.preventDefault()
        $(crtl.noDetentionPlaceCodeInputTarget).val("true").removeAttr('disabled')
        $(crtl.noDetentionPlaceCodeInputTarget).trigger("change")
      })
    }

    if (this.hasWithDetentionPlaceCodeTarget) {
      $(this.withDetentionPlaceCodeTarget).find('a').first().on('click', function(e) {
        e.preventDefault()
        $(crtl.noDetentionPlaceCodeInputTarget).val("false").removeAttr('disabled')
        $(crtl.noDetentionPlaceCodeInputTarget).trigger("change")
        $(crtl.detentionPlaceCodeInputTarget).removeAttr('disabled')
      })
    }

    // if (this.hasBirthDateTarget && this.hasDetentionSinceTarget) {
    //   $(this.birthDateTarget).on('change', function(e) {
    //     if ($(this).val() != '' && $(crtl.detentionSinceTarget).val() == '') {
    //       $(crtl.detentionSinceTarget).pickadate('picker').set('select', $(this).val(), { format: I18n.t("date.formats.mat_datepicker") })
    //     }
    //   })
    //   $(this.birthDateTarget).trigger("change")
    // }

    if (this.hasNoDetentionPlaceCodeInputTarget) {
      $(this.noDetentionPlaceCodeInputTarget).on('change', function(e) {
        if ($(this).val() == "true") {
          $(crtl.detentionPlaceSelectionTarget).addClass("d-none").find('input, select').attr('disabled', 'disabled')
          
          $(crtl.holdingPlaceDataTarget).find('input, select').attr('disabled', 'disabled')
          $(crtl.detentionPlaceDataTarget).find('input, select').attr('disabled', 'disabled')
          $(crtl.detentionPlaceDataWrapperTarget).removeClass("d-none")

          $(crtl.isDetentionPlaceOperatorTargets).each(function() {
            if ($(this).is(":checked")) {
              $(this).trigger("change")
            }
          })
        } else if ($(this).val() == "false") {
          $(crtl.detentionPlaceSelectionTarget).removeClass("d-none").find('input, select').removeAttr('disabled')
          
          $(crtl.holdingPlaceDataTarget).find('input, select').attr('disabled', 'disabled')
          $(crtl.detentionPlaceDataTarget).find('input, select').attr('disabled', 'disabled')
          $(crtl.detentionPlaceDataWrapperTarget).addClass("d-none")
        }
      })
    }

    if (this.hasIsDetentionPlaceOperatorTarget) {
      $(this.isDetentionPlaceOperatorTargets).each(function() {
        $(this).on('change', function(e) {
          if ($(this).is(":checked")) {
            if ($(this).val() == "true") {
              $(crtl.detentionPlaceCodeInputTarget).removeAttr('disabled')
              $(crtl.holdingPlaceDataTarget).addClass("d-none").find('input, select').attr('disabled', 'disabled')
              $(crtl.detentionPlaceDataTarget).removeClass("d-none").find('input, select').removeAttr('disabled')
            } else if ($(this).val() == "false") {
              $(crtl.detentionPlaceCodeInputTarget).val("").attr('disabled', 'disabled')
              $(crtl.submitButtonTarget).removeAttr('disabled')
              $(crtl.detentionPlaceDataTarget).addClass("d-none").find('input, select').attr('disabled', 'disabled')
              $(crtl.holdingPlaceDataTarget).removeClass("d-none").find('input, select').removeAttr('disabled')
            }
          }
        })
      })      
    }

    $(crtl.noDetentionPlaceCodeInputTarget).trigger("change")
  }

  updateEmbryoTransfer(e) {
    let checked = $(e.currentTarget).val()

    if (checked) {
      $('#birth_registration_icsi_false').prop('checked', true);
    }
  }

  updateIcsi(e) {
    let checked = $(e.currentTarget).val()

    if (checked) {
      $('#birth_registration_embryo_transfer_false').prop('checked', true);
    }
  }

  createContact(e){
    const element = e.currentTarget

    let birth_id = this.data.get("birthId")
    let contact_type = element.dataset.createContactType

    let select = $(this[contact_type + "Target"])

    $('#contact-validation-selection-' + contact_type).addClass("d-none")
    $(element).prop("disabled", true)

    let ctrl = this;

    $.ajax({
      type: "POST",
      url: "/birth/create_contact_from_data.js",
      data: "id=" + birth_id + "&contact_type=" + contact_type
    }).then(function (data) {
      var option = new Option(data.label, data.id, true, true);

      select.append(option).trigger('change');

      // update value
      // nécessaire car utilisée pour le updateContactDeclaration et updateContactValidation
      select.trigger({
          type: 'select2:select',
          params: {
              data: data
          }
      });

      // update hint
      select.trigger({
          type: 'select2:selecting',
          params: {
              args: {
                data: data
              }
          }
      });

      ctrl._showSnackbar(I18n.t("birth_registrations.validation.contact_created"))
    });
  }

  createHorse(e){
    const element = e.currentTarget

    let birth_id = this.data.get("birthId")
    let origin_type = element.dataset.createHorseType

    let select = $(this[origin_type + "Target"])

    let ctrl = this;

    $.ajax({
      type: "POST",
      url: "/birth/create_horse_from_data.js",
      data: "id=" + birth_id + "&origin_type=" + origin_type
    }).then(function (data) {

      $('#origin-validation-selection-' + origin_type).addClass("d-none")
      $(element).prop("disabled", true)

      var option = new Option(data.label, data.id, true, true);

      select.append(option).trigger('change');

      // update value
      // nécessaire car utilisée pour le updateContactDeclaration et updateContactValidation
      select.trigger({
          type: 'select2:select',
          params: {
              data: data
          }
      });

      // update hint
      select.trigger({
          type: 'select2:selecting',
          params: {
              args: {
                data: data
              }
          }
      });

      ctrl._showSnackbar(I18n.t("birth_registrations.validation.horse_created"))
    }).fail(function(){
      ctrl._showSnackbar(I18n.t("birth_registrations.validation.horse_creation_failed"))
    });
  }

  updateContactData(e){

    e.preventDefault()

    const element = e.currentTarget

    let birth_id = this.data.get("birthId")
    let contact_type = element.dataset.updateContactType
    let data_type = element.dataset.updateDataType
    let contact_id = $(this[contact_type + "Target"]).val()
    let ctrl = this;
    
    $.ajax({
      type: "POST",
      url: "/birth/add_data.js",
      data: "id=" + birth_id + "&contact_type=" + contact_type + "&data_type=" + data_type + "&contact_global_id=" + contact_id,
      success: function(){
        $(".popover.show").remove()
        $(element).remove()
      }
    }).then(function(data){
      ctrl._updateContactValidationFromTarget(element)

      ctrl._showSnackbar(data_type=="address"?I18n.t("birth_registrations.validation.contact_address_added"):I18n.t("birth_registrations.validation.contact_detail_added"))
    });
  }

  updateContactValidation(e){
    const element = e.currentTarget

    this._updateContactValidationFromTarget(element)
  }

  updateContactDeclaration(e){
    const element = e.currentTarget

    let birth_id = this.data.get("birthId")
    let contact_type = element.dataset.refreshContactType || element.dataset.updateContactType || element.dataset.createContactType

    let contact_global_id = $(this[contact_type + "Target"]).val()
    let partial_name = "birth_registrations/form_steps_validation/contact_declaration_card_body"
    let into = contact_type + "_declaration_card_body"

    $.ajax({
      type: "POST",
      url: "/contacts/show.js",
      data: "birth_id=" + birth_id + "&contact_type=" + contact_type + "&contact_global_id=" + contact_global_id + "&partial_name=" + partial_name + "&into=" + into
    })
  }
  
  updateOriginValidation(e){
    const element = e.currentTarget

    let origin_type = element.dataset.refreshOriginType

    let horse_id = $(element).val()
    let partial_name = "birth_registrations/form_steps_validation/origin_validation_card"
    let into = origin_type + "_validation_card"

    $.ajax({
      type: "POST",
      url: "/horses/show_partial.js",
      data: "horse_id=" + horse_id + "&partial_name=" + partial_name + "&into=" + into
    })
  }

  _updateContactValidationFromTarget(element){
    let contact_type = element.dataset.refreshContactType || element.dataset.updateContactType || element.dataset.createContactType

    let contact_global_id = $(this[contact_type + "Target"]).val()
    let partial_name = "birth_registrations/form_steps_validation/contact_validation_card_body"
    let into = contact_type + "_validation_card_body"

    let ctrl = this

    $.ajax({
      type: "POST",
      url: "/contacts/show.js",
      data: "contact_global_id=" + contact_global_id + "&contact_type=" + contact_type + "&partial_name=" + partial_name + "&into=" + into
    })
  }

  _showSnackbar(message){
    let snackEvent = new CustomEvent("snackbar.show", {"detail": {"message": message}});
    setTimeout(function() {
      document.dispatchEvent(snackEvent);
    }, 200);
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['countryCode', 'aslWrapper', 'aslInput', 'individualRadio', 'nationalNumberLabel', 'nationalNumberLabelAbbr'];

  connect() {
    this.hideOrShowASL()
  }

  hideOrShowASL() {
    if (this.individualRadioTarget.checked && this.countryCodeTarget.value == "IT") {
      this.nationalNumberLabelTarget.classList.remove("optional")
      this.nationalNumberLabelTarget.classList.add("required")

      if (!this.hasNationalNumberLabelAbbrTarget) {
        this.nationalNumberLabelTarget.insertAdjacentHTML('beforeend', `
          <abbr title="${I18n.t("btn.tooltips.required")}" data-target="contact.nationalNumberLabelAbbr">*</abbr>
        `);
      }
    } else {
      this.nationalNumberLabelTarget.classList.remove("required")
      this.nationalNumberLabelTarget.classList.add("optional")
      if (this.hasNationalNumberLabelAbbrTarget) {
        this.nationalNumberLabelAbbrTarget.remove()
      }
    }

    if (this.countryCodeTarget.value == "IT") {
      this._showContent($(this.aslWrapperTarget))
    } else {
      this._hideContent($(this.aslWrapperTarget))
      $(this.aslInputTarget).trigger('change')
    }
  }

  _showContent(elements) {
    $.each(elements, function(key, value) {
      $(value).prop('disable', false).removeClass("d-none")
    });
  }

  _hideContent(elements) {
    $.each(elements, function(key, value) {
      $(value).addClass("d-none").find('input, select, textarea').val('').prop('disable', true)
    });
  }

}

import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';
import 'dayjs/locale/it';

export default class extends Controller {

  connect(e) {
    // requis pour validation stricte avec dayjs
    let customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)

    // https://amsul.ca/pickadate.js/date/#translations :
    // When using translations, specify the formatSubmit and data-value to ensure the date parses correctly regardless of locale.
    if ($(this.element).val() != "") {
      let valueDate = dayjs($(this.element).val(), I18n.t("date.formats.mat_datepicker").toUpperCase(), I18n.locale, true)
      
      if (valueDate.isValid()) {
        $(this.element).attr("data-value", valueDate.format('YYYY-MM-DD'))
      }
    }

    let picker = $(this.element)
    picker.pickdate({
      // .filter(item => item) pour supprimer la première valeur nil dans month_names et abbr_month_names (rails.en.yml, rails.nl.yml)
      "monthsFull": I18n.t("date.month_names").filter(item => item).map(string => string.toLowerCase().replace(/^\w/, c => c.toUpperCase())),
      // .filter(item => item) pour supprimer la première valeur nil dans month_names et abbr_month_names (rails.en.yml, rails.nl.yml)
      "monthsShort": I18n.t("date.abbr_month_names").filter(item => item).map(string => string.toLowerCase().replace(/^\w/, c => c.toUpperCase())),
      "weekdaysShort": I18n.t("date.abbr_day_names").map(string => string.substring(0,2).replace(/^\w/, c => c.toUpperCase())),
      "weekdaysFull": I18n.t("date.abbr_day_names").map(string => string.toLowerCase().replace(/^\w/, c => c.toUpperCase())),
      "selectYears": 1000,
      "selectMonths": true,
      "ok": I18n.t("btn.labels.date_picker_ok"),
      "cancel": I18n.t("btn.labels.cancel"),
      "firstDay": 1,
      "format": I18n.t("date.formats.mat_datepicker"),
      // https://amsul.ca/pickadate.js/date/#translations :
      // When using translations, specify the formatSubmit and data-value to ensure the date parses correctly regardless of locale.
      "formatSubmit": "yyyy-mm-dd",
      "container": "body",
      "closeOnSelect": true,
      "min": new Date(1900,0,1),
      "max": !(this.data.get("allowFuture") && this.data.get("allowFuture") == "true")
    })
    // Disable Opening of Date Picker on click or focus
    picker.off("focus")
    picker.off("click")
    // Disable read only on Date Picker field
    picker.removeAttr("readonly")
    let calendarIcon = picker.next(".picker-opener")
    if (calendarIcon.length < 1) {
      calendarIcon = $("<i class=\"material-icons picker-opener\">today</i>")
      picker.after(calendarIcon)
    }

    if (!this.data.has("disabled") || !!this.data.get("disabled")) {
      calendarIcon.on("click", function(e){
        picker.pickadate('picker').open()
        e.stopPropagation()
      })
    }

    // Focus sur le champs du picker après sa  fermeture

    picker.pickadate('picker').on({
      close: function() {
        picker.focus()
      }
    })

    // Apply Input Mask to Date Picker

    let im = Inputmask("99/99/9999", { "placeholder": "––/––/––––" })
    picker.attr("placeholder", "––/––/––––")
    im.mask(picker)

    // Contraindre l'année
    let limiterIdOrName = this.data.get("limitYearBy")

    let ctrl = this

    if (limiterIdOrName) {

      $('body').on("change", limiterIdOrName, function(e) {
        if ($(limiterIdOrName).attr("type") == "radio") {
          ctrl._limitYear($(limiterIdOrName + ":checked").val())
        } else if ($(limiterIdOrName).val() != "") {
          ctrl._limitYear($(limiterIdOrName).val())
        }
      });

      $(limiterIdOrName).trigger("change")
    }

    $(this.element).on('blur keypress', function (e) {
      let dateInputed = dayjs($(this).val(), I18n.t("date.formats.mat_datepicker").toUpperCase(), I18n.locale, true)

      if (dateInputed.isValid() && dateInputed >= picker.pickadate('picker').get("min").obj && dateInputed <= picker.pickadate('picker').get("max").obj){
        picker.pickadate('picker').set('select', dateInputed.toDate())
      } else {
        $(picker.pickadate('picker')._hidden).val("   ") // "   " -> pour être parsé comme blank? par ruby
      }
    })
  }

  _limitYear(year) {
    $(this.element).pickadate('picker').set('min', [year,0,1]).set('max', [year,11,31])
  }

}

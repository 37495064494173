import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "warning" ]

  connect() {
    this.showHideWarning()
  }

  showHideWarning() {
    if (this.checkboxTarget.checked) {
      this.warningTarget.classList.remove("d-none")
    } else {
      this.warningTarget.classList.add("d-none")
    }
  }
}

import { Controller } from "@hotwired/stimulus";

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ['inputWrapper', 'input', 'invalid']
  
  connect() {
    this.search = debounce(this.search, 200).bind(this)

    this.search()
  }

  search(e) {
    let term = $(this.inputTarget).val()

    if (term.trim() == '') {
      if ($(this.inputTarget).is(":disabled")) {
        this.inputTarget.setCustomValidity("")
      } else {
        this.invalidCodeEntered()
      }

      $(this.infoTarget).addClass('d-none')
      return
    }
    
    let ctrl = this

    $.ajax({
      type: "GET",
      url: "/detention_places/search_by_code/" + term
    }).done(function (data) {
      ctrl.inputTarget.setCustomValidity("")
    }).fail(function (jqXHR, textStatus) {
      if (jqXHR.status == 404) {
        ctrl.invalidCodeEntered()
      } else {
        console.log("unexpected error while searching for DP")
      }
    }).always(function() {
      ctrl.checkValidCode()

      $(ctrl.inputTarget).trigger('change')

      let formValidationController = ctrl.application.getControllerForElementAndIdentifier(ctrl.element.closest('form'), "form-validation")

      if (formValidationController) {
        formValidationController.validateField(ctrl.inputTarget)
      }
    });
  }

  checkValidCode(e) {
    if (this.inputTarget.checkValidity()) {
      const event = new CustomEvent("detention-place-valid-code")
      document.dispatchEvent(event)
    } else {
      const event = new CustomEvent("detention-place-invalid-code")
      document.dispatchEvent(event)
    }
  }

  setValue(e) {
    $(this.inputTarget).val(e.detail.number)
    this.search()
  }

  reset(e) {
    $(this.inputTarget).val('')
    this.inputTarget.setCustomValidity("")
  }

  invalidCodeEntered(e) {
    this.inputTarget.setCustomValidity(I18n.t("detention_places.invalid_number"))
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['private', 'origin', 'uelnType', 'uelnField', 'uelnNationalId', 'uelnCountry', 'uelnDatabase'];

  connect(){

  }


  changePrivate(e) {


      let val = this._getTargetValue(this.privateTarget)
      if (val && val.length > 0) {
        // TODO Logique diff selon SB
        if (this.data.get("database") == 2 && val.match(/^[A-V]/i)) {
          this._triggerSbHorse()
        } else if (this.data.get("database") == 4 && val.match(/^\d{4}\/\d*$/i)) {
          this._triggerAwctbHorse(e)
        } else {
          this._triggerExHorse()
        }
      } else {
        this._triggerExHorse()
      }
      $(this.privateTarget).removeClass("is-invalid").parents(".form-group").removeClass("form-group-invalid")

  }

  changeOrigin(e) {
  }

  changeOriginOLD(e) {
    if (this.data.get("database") == 2) {
      let val = this._getTargetValue(this.originTarget)
      if (val && val.length > 0) {
        switch(this.uelnType) {
          case "temp":
            this.changeUelnType()
            break;
          case "manual":
            let uelnId = $(this.uelnNationalIdTarget).val()
            if (uelnId.length < 1 || uelnId == "000000000" || uelnId == "999999999") {
              this._setTargetValue(this.uelnTypeTarget, "temp")
              this.changeUelnType()
            }
          default:

        }
      } else {
        // Ne rien faire puisqu'il n'y a pas d'alias
      }
    }
  }



  changeUelnType() {

      this._generateUeln();
      switch(this.uelnType) {
        case "temp":
          $(this.uelnFieldTargets).addClass("disabled").attr("disabled", true)
        case "auto":
          $(this.uelnFieldTargets).addClass("disabled").attr("disabled", true)
          break
        default:
          $(this.uelnFieldTargets).removeClass("disabled").removeAttr("disabled")
      }

  }

  get uelnType() {
    return $(this.element).find(".horse_ueln_type select").val();
  }

  get uelnInputGroups() {
    return $(this.element).find(".form-group:not(.horse_ueln_type)");
  }

  /**
   * @private
   */
   _getTargetValue(target) {
     return $(target).val()
   }

   _setTargetValue(target, val) {
     $(target).val(val)
   }

   _triggerSbHorse() {
     let val = this._getTargetValue(this.privateTarget)
     this._setTargetValue(this.originTarget, val)
     $(this.originTarget).attr("readonly", true)
     this._forceUelnAuto()

   }

   _triggerExHorse() {
     $(this.originTarget).attr("readonly", false)
     if (this.data.get("database") == 2) {
       this._disableUelnAuto()
       if (this.uelnType=="auto") {
         this._setTargetValue(this.uelnTypeTarget, "manual")
         this.changeUelnType()
       }
     }
   }

   _triggerAwctbHorse(e) {
      let val = this._getTargetValue(this.privateTarget)
      this._setTargetValue(this.originTarget, val)
      if (e.detail && e.detail.from_magic_wand) {
        this._setTargetValue(this.uelnTypeTarget, "auto")
        this.changeUelnType()
        this._generateUeln()
      }
   }

   _forceUelnAuto() {
      this._setTargetValue(this.uelnTypeTarget, "auto")
      $(this.uelnTypeTarget).addClass("disabled").attr("readonly", true)
      $(this.uelnTypeTarget).find("option[value=manual], option[value=temp]").addClass("d-none")
      $(this.uelnTypeTarget).find("option[value=auto]").removeClass("d-none")
      this.changeUelnType()
      this._generateUeln()
   }

   _setUelnTemp() {

   }

   _checkAvailableUelnType(){
     //let origin = this._getTargetValue(this.originTarget)
     //let private = this._getTargetValue(this.privateTarget)
   }

   _disableUelnAuto() {
      $(this.uelnTypeTarget).removeClass("disabled")
      this._uelnTypeReadonly()
      this._enableUelnType("manual")
      this._enableUelnType("temp")
      this._disableUelnType("auto")
   }

   _uelnTypeReadonly(){
     $(this.uelnTypeTarget).attr("readonly", false)
   }

   _uelnTypeReadAndWrite(){
     $(this.uelnTypeTarget).removeAttr("readonly")
   }

   _disableUelnType(type) {
     $(this.uelnTypeTarget).find("option[value="+type+"]").addClass("d-none")
   }

   _enableUelnType(type) {
     $(this.uelnTypeTarget).find("option[value="+type+"]").removeClass("d-none")
   }


  _disableUelnFields() {
    this.uelnInputGroups.addClass("disabled");
    this.uelnInputGroups.find("label").addClass("disabled");
    this.uelnInputGroups.find("input, select").addClass("disabled").attr("disabled", "disabled");
  }

  _enalbleUelnFields() {
    this.uelnInputGroups.removeClass("disabled");
    this.uelnInputGroups.find("label").removeClass("disabled");
    this.uelnInputGroups.find("input, select").removeClass("disabled").removeAttr("disabled");
  }

  _generateUeln() {
    let type = this._getTargetValue(this.uelnTypeTarget)
    let uelnId = ""
    let padWith = "999999999"
    switch(this.uelnType) {
      case "temp":
        uelnId = this._getTargetValue(this.privateTarget)
        uelnId = uelnId.replace(/\W/, "0")
        uelnId = (padWith + uelnId).slice(-padWith.length)
        this._setTargetValue(this.uelnNationalIdTarget, uelnId)
        this._setTargetValue(this.uelnDatabaseTarget, "999")
        $(this.uelnCountryTarget).append($("<option value=\"999\">999</option>")).val('999')
        this._setTargetValue(this.uelnCountryTarget, "999")
        break
      case "auto":

        uelnId = this._getTargetValue(this.privateTarget)

        if (this.data.get("database") == 4 && this._getTargetValue(this.privateTarget).charAt(4) == '/') {
          uelnId = uelnId.substring(0,4) + "00000".slice(uelnId.slice(5).length) + uelnId.slice(5)
        } else {
          uelnId = uelnId.replace(/\W/, "0")
          padWith = "000000000"
          uelnId = (padWith + uelnId).slice(-padWith.length)
        }
        this._setTargetValue(this.uelnNationalIdTarget, uelnId)
        this._setTargetValue(this.uelnDatabaseTarget, $(this.uelnDatabaseTarget).data("default-value"))
        this._setTargetValue(this.uelnCountryTarget, $(this.uelnCountryTarget).data("default-value"))
      default:
        $(this.uelnCountryTarget).find("option[value=999]").remove()

        // othing Yet
    }
  }

  _oldgenerateUeln() {
    let countryInput = this.uelnInputGroups.filter(".horse_ueln_country").find("select");
    let databaseInput = this.uelnInputGroups.filter(".horse_ueln_database").find("input")
    let nationalIdInput = this.uelnInputGroups.filter(".horse_ueln_national_id").find("input")
    let privateAlias = $(".horse_private_alias input").first().val()
    let padWith = "000000000"



    switch(this.uelnType) {
      case "temp":
        countryInput.append($("<option value=\"999\">999</option>")).val('999')
        databaseInput.val('999')
        padWith = "999999999"
        break
      default:
        countryInput.val(countryInput.data("default-value"))
        databaseInput.val(databaseInput.data("default-value"))
    }
    nationalIdInput.val((padWith + privateAlias).slice(-padWith.length))

  }
  _removeTempValue() {
    let countryInput = this.uelnInputGroups.filter(".horse_ueln_country").find("select");
    let databaseInput = this.uelnInputGroups.filter(".horse_ueln_database").find("input")
    countryInput.find("option[value=999]").remove()
    if (countryInput.val().length < 1) {
      countryInput.val(countryInput.data("default-value"))
    }
    if (databaseInput.val() == "999") {
      databaseInput.val(databaseInput.data("default-value"))
    }
  }


}
